import {
  arr1,
  arr10,
  arr11,
  arr12,
  arr13,
  arr14,
  arr2,
  arr3,
  arr4,
  arr5,
  arr6,
  arr7,
  arr8,
  arr9,
} from "./industrialVisitDetails";

export const TilesData = [
  {
    id: 1,
    images: arr1,
    date: `May 18, 2022`,
    scrollid: "Industrial-visit-organized-by-CSE-department",
    heading: `"Industrial Visit organized by CSE Department"`,
    description: `The Department of Computer Science and Engineering has organized a industrial visit to Solitaire Infosys Mohali, for the students of B.Tech(CSE)-4th.`,
  },
  {
    id: 2,
    images: arr2,
    date: `Oct 29, 2018`,
    scrollid: "Industrial-visit-at-GNA-Gears-Mehtiana",
    heading: `"Industrial visit at GNA Gears, Mehtiana "`,
    description: `On 29th October 2018, Department of Mechanical Engineering and T&P Cell organized an Industrial Visit of the Vth Semester MechanicalEngineering students to GNA GEARS Mehtiana. The purpose of visit was to enhance the practical & technical skills related to the CNC technology, Dry machining used in manufacturing industry along with work flow of industry. Advanced CAM technology was practically explained by the experts Mr. Amit and Mr.Gurmeet Singh. Er. Sarbrinder Pal Singh, AP, ME Deptt. and Mr. Harpreet Singh, Lab. Tech. accompanied the students and guided them about the future job prospects in Mechanical Engineering. Dr. Sukhraj Singh, HOD Department of Mechanical Engineering said these types of visits are necessary to aware the students about the latest technology being used by industry. Er. R. S. Deol (Director, LKCE) appreciated the Department of ME & Mr. Arun Dutta, Assistant Director T&P for arranging the Industrial visit. He said that that these kind of industrial visits help the students to understand the actual practical implementation of the concepts which they are studying theoretically. S. Sukhbir Singh Chatha (Director Academics, KCL Group) said that LKCE is committed to strengthen the academic learning of their students by keeping this practice of arranging industrial visits regularly..`,
  },
  {
    id: 3,
    images: arr3,
    date: `May 18, 2022`,
    scrollid: "Industrial-visit-organized-by-department-of-applied-science",

    heading: `"Industrial Visit organized by Department of Applied Science"`,
    description: `The Department of Applied Science of Lyallpur Khalsa College of Engineering organized an industrial visit for the Ist year students of all the branches ( CSE & ECE & ME & CE) on 07/10/2018 at Central Institute of Handtools and Verka milk plant. The students were made aware of various tools , machinery and their functions. The faculty members Dr. Munish Aggarwal, H.O.D, Applied Science, Ms. Jagriti Dutta , Ms. Bani Dhaul and Ms. Anamika Sharma accompanied the students. Dr. R.S. Deol (Director LKCE) appreciated the efforts of the faculty & motivated them to conduct such kind of activities in future also.`,
  },
  {
    id: 4,
    images: arr4,
    scrollid: "Industrial-visit-organized-by-department-of-civil-engineering",
    date: `May 18, 2022`,
    heading: `"Industrial Visit organized by Department of Civil Engineering"`,
    description: `The Department of Civil Engineering of Lyallpur Khalsa College of Engineering arranged industrial visits for the students of Civil engineering. 3rd semester students went to Panesar RMC Batching Plant and 5th semester students went to Sewage treatment Plant, Pholriwal, Jalandhar. These visits proved to be a wonderful learning experience as it helped the students to gain better knowledge of the practical aspects of Ready Mix concrete plant and Sewage treatment plant. The students of 3rd semester were accompanied by Er. Harsh Kumar and Er. Divya Chopra and students of 5th semester were accompanied by Er. Gagandeep Arora and Er. Harsh Kakkar (Asstt. Professors, CE) who shared their knowledge and experience. All together the visit was an enthralling experience and LKCE look forward to have such visits more frequently in the future. S. Sukhbir Singh Chatha (Director Academics, KCL Group) said that LKCE is committed to strengthen the academic learning of their students by keeping this practice of arranging industrial visits regularly.Er. R.S. Deol (Director, LKCE) appreciated the Department of Civil Engineering for arranging the Industrial visit. He said that that these kind of industrial visits help the students to understand the actual practical implementation of the concepts which they are studying theoretically`,
  },
  {
    id: 5,
    images: arr5,
    scrollid: "Industrial-visit-to-guru-gobind-singh-thermal-power-plant-ropar",
    date: `Sep 19, 2018`,
    heading: `"Industrial Visit to Guru Gobind Singh Thermal Power Plant Ropar "`,
    description: `Theory without Practice is Blind, where Practical without Theory is Deaf " Industrial visit is an important T&P activity that gives a clear insight of practical exposure to the engineering scholars. As a part of wholesome learning process, One day Industrial visit to "Guru Gobind Singh Super Thermal Power Plant, Ropar" was organized by the ECE and ME department and T&P Department of LKCE on Tuesday, 19th September, 2018. Total 51 students (27 from ME and 24 from ECE) accompanied by Er. Harmanpreet Singh, AP ME deptt. and Er. Jasmeen Kaur, AP ECE Deptt. participated in this visit. Er. R. S. Deol, Director LKCE and Prof. Sukhbir Singh Chatha, Director Academic Affairs appreciated the efforts of departments and also motivated to plan these types of visits in future also.`,
  },
  {
    id: 6,
    images: arr6,
    date: `Sep 19, 2018`,
    scrollid: "Industrial-visit-organized-by-it-department",
    heading: `"Industrial Visit organized by IT Department"`,
    description: `The Training and Placement Cell of Department of Information Technology of LKCTC Jalandhar has organized Industrial Visit for BCA and MCA students to Sebiz Mohali. The objective of Industrial Visit is to provide an exposure to students about practical working environment. It also provide students a good opportunity to gain full awareness about industrial practices. Through industrial visit students get awareness about new technologies.`,
  },
  {
    id: 7,
    images: arr7,
    date: `Sep 19, 2018`,
    scrollid:
      "A-virtual-industrial-visit-to-EASTMAN-industries-PvtLtd-organized-by-School-of-management-of-LKCTC-for-its-students",
    heading: `"A Virtual Industrial Visit to EASTMAN Industries Pvt. Ltd organized by School of Management of LKCTC for its students."`,
    description: `A virtual Industrial Tour of MBA 2nd BBA-2nd sem, BBA-4th sem, B.com-2nd sem and B.Com (H)-4th , B.Com (H)-6th &amp; BBA-6th Semester students was organized to EASTMAN Industries Pvt. Ltd ,Ludhiana by the Department of Management. The event was coordinated by Dr Ritu Rana, Dr Tarunjit Singh and Mr Arun Dutta (Deputy Director of Training &amp; Placement). A total of Ninety-Two students of the above classes were virtually accompanied by Dr Tarunjit Singh from the department. Ever since its inception in 1982, Eastman has been a pioneer in exports of a complete range of MTB, BMX and road Bicycles and set new challenges for itself having successfully forayed in markets like Latin America with a strong presence in Brazil, Mexico, Argentina and in Europe Portugal, C.I.S. Countries like Ukraine and Belarus. Eastman created an outsourcing network in Hangzhou, China in 2002. The Virtual tour witnessed visit of various departments like Inbound Logistics, Warehouse Management ,Quality Assurance ,5S Japanese concept implementation ,Paint Shop ,Assembly Line etc.`,
  },
  {
    id: 8,
    images: arr8,
    date: `May 18, 2022`,
    scrollid: "Industrial-visit-organized-by-CE-department",
    heading: `" Industrial Visit organized by CE Department"`,
    description: ` The department of CE of Lyallpur Khalsa College of engineering
    arranged an industrial visit at Nirmal Kutia, Seechewal &Sultanpur
    Lodhi.The purpose of this visit was to enable the students to gain
    exposure to industrial environment and grass root trends in the
    field of Environmental Engg.& sewage water treatment. The students
    of CE 3rd& CE 5th semester were accompanied by Er. Tarundeep Singh
    (Asstt. Professor,CE) &Er.Divya Chopra (Asstt. Professor, CE) who
    shared their knowledge and experience. Students had the honor to
    meet and had a live discussion session with Balbir Singh Seechewal
    Ji who single-handed cleaned and restored Kali Bein River, a 160 km
    long tributary of Beas in Doaba region of Punjab.`,
  },

  {
    id: 9,
    images: arr9,
    date: `2023`,
    scrollid: "Industrial-visit-organized-by-hm-department",
    heading: `"Industrial Visit organized by HM Department"`,
    description: `School of hotel management at Lyallpur Khalsa College Technical Campus Jalandhar Organised an industrial visit for the students of Hotel Management. The visit was planned with Best Western Plus, Jalandhar. The students were flagged off by Dean Hotel Management - Mr. Deepak Paul and HOD HM- Mr. Arashdeep Singh. The students were able to learn the new trends and operating procedures in the hotel. The Students were assisted by Mr. Manpreet Singh, HR Manager of Hotel Best Western Plus Jalandhar. 
Director LKCTC, Dr. R.S. Deol congratulated the efforts of the department for successful completion of the visit and asked the HOD to plan more such events.
Director AA, KCL Group, S. Sukhbir Singh Chatha appreciated the students and wished them luck for their future endeavors.
`,
  },

  {
    id: 10,
    images: arr10,
    date: `2023`,
    scrollid: "Industrial-visit-organized-by-hm-department-2",
    heading: `"Industrial Visit organized by HM Department"`,
    description: `The Department of Hotel Management has organized a industrial visit to Courtyard by Marriott, Amritsar, for the students of B.Tech(CSE)-4th.
`,
  },
  {
    id: 11,
    images: arr11,
    date: `12 March, 2024`,
    scrollid: "Industrial-visit-organized-by-IT-department-2",
    heading: `"Industrial Visit organized by IT Department"`,
    description: `The Department of IT at Lyallpur Khalsa College Technical Campus organized a trip to Mata Chintpurni ,Mata Baglamukhi and Bombay picnic spots for BCA-6th, MCA-4th, and MCA-2nd students.
Dr. R.S Deol, Director, and Dr. Nidhi Chopra, HoD IT LKCTC, commended the department's efforts, highlighting the role of such tours in fostering communication skills essential for societal engagement and personal growth. They emphasized the significance of these experiences in contributing to individual development. Additionally, they pledged to organize more tours and visits in the future, aiming to enrich students' experiential learning opportunities. This initiative underscores the college's commitment to holistic education and skill enhancement.
`,
  },
  {
    id: 12,
    images: arr12,
    date: `2024`,
    scrollid: "Industrial-visit-organized-by-mgmt-department-2",
    heading: `"Industrial Visit organized by Mgmt Department"`,
    description: `Lyallpur Khalsa College Technical Campus, School of Management in association with Institution’s Innovation Council, organized an industrial visit to Sonalika International Tractors, Hoshiarpur. The company was incorporated in the year 1969 .It is the Largest Tractor manufacturing facility in the country. It takes 1 Minute and 32 Seconds to manufacture a tractor. It has a manufacturing capacity of approximately 3 Lakh Tractors in a year.
The Students learnt a lot about the manufacturing processes and visited various departments at Sonalika International Tractors Pvt Ltd. The Trainer briefed the students about the history of the company, various domains like Logistics, Inventory Management, Six Sigma, Lean Manufacturing, Research and Development, Quality Assurance, Marketing, Finance and Human Resource. 
The Industrial visit was arranged by Mr.Kunal Verma (Assistant Director, Training and Placements, LKCTC) and coordinated by Dr.Sonu Dua (Associate Professor), Ms.Anterpreet Talwar (Assistant Professor) and Ms.Nitika Sehgal (Assistant Professor) from School of Management.
S.Sukhbir Singh Chatha (Director,Academic Affairs-KCL Group of Institutions) appreciated the efforts of the department and congratulated the Coordinators and the whole department for arranging the same.
Dr.R.S Deol, (Director, LKCTC) said that industrial visits help the students to combine the theoretical concepts with practice. It is important part of technical education and adds to the skill set of students to make them placable.
Dr.Inderpal Singh (Dean Management Studies) said that more industrial visits are being arranged in the coming days for other courses of the department to enhance experiential learning of students which is an indispensable part of education.`,
  },
  {
    id: 13,
    images: arr13,
    date: `2024`,
    scrollid: "Industrial-visit-organized-by-mgmt-department-3",
    heading: `"Industrial Visit organized by Management Department"`,
    description: ` Lyallpur Khalsa College Technical Campus, School of Management in association with Institution’s Innovation Council, organized an industrial visit to Vardhman Textiles ,Baddi for the students of MBA.This subsidary unit was incorporated in the year 1991. Vardhman is the largest vertically integrated textile manufacturer in India. Annually, it produces 2,40,000 metric tons of yarn and 220 million metres of woven fabric.Vardhman is a prime producer and exporter of premium quality yarns in markets of the EU, Japan, USA and Asia.
The Senior Vice president , Finance Head , HR Managers addressed the students and briefed them about various verticals and domains of Vardhman's business .The students were encouraged to ask questions in which students interacted with the officials and the discussion was very meaningful and knowledgeable. The trainers took the students to various departments including , Spinning , weaving, etc.where high Tech machines were working at their optimum capacity . 
The Industrial visit was arranged by Mr.Kunal Verma (Assistant Director, Training and Placements, LKCTC) and coordinated by Dr.Inderpal Singh ,Dean Management Studies, Dr.Tarunjit Singh (Associate Professor), Ms.Dhriti Jain (Assistant Professor) and Ms.Aman Pasricha (Assistant Professor) from School of Management.
S.Sukhbir Singh Chatha (Director,Academic Affairs-KCL Group of Institutions) appreciated the efforts of the department and congratulated the Coordinators and the whole department for arranging the same.
Dr.R.S Deol, (Director, LKCTC) said that industrial visits help the students to combine theory with practice. It is important part of technical education and adds to the skill set of students.
Dr.Inderpal Singh (Dean Management Studies) said that more industrial visits are being arranged in the coming days for other courses to enhance experiential learning of students which is an indispensable part.`,
  },

  // {
  //   id: 9,
  //   // images: arr9,
  //   date: ``,
  //   heading: `""`,
  //   description: ``,
  // },

  {
    id: 14,
    images: arr14,
    date: `2024`,
    scrollid: "Industrial-visit-organized-by-mls",
    heading: `"Visit to Ghai Hospital organized by MLS Department"`,
    description: `Students from the Medical Laboratory Science (MLS) Department at LKCTC organized a visit to Ghai Hospital to enhance their understanding of their field. The visit aimed to provide hands-on experience and insight into real-world medical practices. Engaging with professionals and observing various laboratory procedures, students gained valuable knowledge about diagnostic techniques, equipment usage, and patient care. This practical exposure not only enriched their theoretical understanding but also inspired them to excel in their future careers as medical laboratory scientists. Such initiatives bridge the gap between academia and practical application, fostering well-rounded professionals in the healthcare sector.`,
  },
];
