import avatar01 from "../../../assets/images/LG_Software.jpg";
import avatar02 from "../../../assets/images/piinfocomm.jpg";
import defaultLogo from "../../../assets/images/logodemo.jpeg";

export const arr = [
  {
    date: "17th March 2023",
    heading: "ONLINE CAMPUS RECRUITMENT DRIVE BY PIE INFOCOMM",
    eligibility: "B. Tech. CSE, ME,CE & ECE students (2023 batch)",
    designation: ` Post according branch*
          *CS/IT* -JR. SOFTWARE DEVELOPER
          *EC/EN*- JR. AUTOMATION ENGINEER
          *CIVIL/MECHANICAL*- AUTOCAD DESIGNER`,
    Package: `₹ 4,50,000 is for B.Tech and ₹ 5,50,000 is for MBAMCA
                Package for Diploma Engineering 3,50,00/-rs3.6 LPA - 1st Year & 4.6 LPA - 2nd Year`,
    image: avatar02,
  },
  {
    date: "April 17 2023",
    heading: "CAMPUS RECRUITMENT DRIVE BY OM CAREERS",
    eligibility: "MBA",
    designation: "MANAGEMENT TRAINEE",
    Package: "5.24 LPA- 5.60 LPA",
    image: defaultLogo,
  },
  {
    date: "APRIL MONTH 2023",
    heading: "CAMPUS RECRUITMENT DRIVE BY VENTURE PACT",
    eligibility: "BE/B.TECH/M.TECH/M.SC IT",
    designation: "SOFTWARE DEVELOPMENT",
    Package: "4 LPA - 6 LPA",
    image: defaultLogo,
  },
  {
    date: "APRIL MONTH 2023",
    heading: "CAMPUS RECRUITMENT DRIVE BY 75WAY TECHNOLOGIES PVT. LTD",
    eligibility: "B. Tech. - CSE/MCA/MBA",
    designation: "SOFTWARE DEVELOPMENT EXECUTIVE/BDE/QA ENGINEER",
    Package: "3 LPA TO 6 LPA",
    image: defaultLogo,
  },
  {
    date: "April 2022",
    heading: "Online Campus Recruitment Drive By LG",
    eligibility:
      "B.E/B.Tech Computer Science/IT/ECE stream and only 2022 Pass out 60% Throughout",
    designation: "Developer",
    location: "Bangalore Hybrid model",
    lastDate: "26th April 2022 3:00 PM",
    onlineAssessment: "29th April 2022 Time : 11 AM to 2 PM",
    Package: "6 LPA",
    image: avatar01,
  },
];
