import { FaLink } from "@react-icons/all-files/fa/FaLink";
import HeaderBanner from "../../../components/HeaderBanner";
import HeaderBanner1 from "../../../assets/pdf/tutorial.pdf";
import React from "react";

//import React, { Component } from 'react';
// import ReactTable from "react-table";np

//import Gallery2 from "../../../assets/PDF/tutorial";

//import officeCircular from "../../assets/pdf/office-circular.pdf";

const SSR = () => {
  const NAAC = [
    {
      title: "Extended Profile",
      metrics: [
        {
          id: "1.1",
          description:
            "Number of students year wise during the last five years",
          downloadLink: "#",
        },
        {
          id: "2.1",
          description:
            "Number of Full time teachers during the last five years",
          downloadLink: "#",
        },
        {
          id: "2.2",
          description:
            "Number of full time teachers year wise during the last five years",
          downloadLink: "#",
        },
        {
          id: "3.1",
          description:
            "Expenditure Excluding salary component year wise during the last five years",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criteria 1 – Curricular Aspects",
      metrics: [
        {
          id: "1.2.1",
          description: "Add on / Certificate / Value Added Program Offered",
          downloadLink: "#",
        },
        {
          id: "1.2.2",
          description: "Students Enrolled in Program Offered in 1.2.1",
          downloadLink: "#",
        },
        {
          id: "1.3.2",
          description:
            "Students Undertaking Project Work / Field Work / Internships",
          downloadLink: "#",
        },
        {
          id: "1.4.1",
          description:
            "Feedback on the Academic Performance and Ambience of the Institution",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criteria 2 - Teaching - Learning and Evaluation",
      metrics: [
        {
          id: "2.1.1",
          description: "Enrolment Percentage",
          downloadLink: "#",
        },
        {
          id: "2.1.2",
          description:
            "Seats Filled Against Seats Reserved for Various Categories",
          downloadLink: "#",
        },
        {
          id: "2.2.1",
          description: "Student – Full time Teacher Ratio for AY",
          downloadLink: "#",
        },
        {
          id: "2.4.1",
          description: "Full Time Teachers Against Sanctioned Posts",
          downloadLink: "#",
        },
        {
          id: "2.4.2",
          description: "Full Time Teachers with Ph. D. Degree",
          downloadLink: "#",
        },
        {
          id: "2.6.3",
          description: "Pass Percentage of Students",
          downloadLink: "#",
        },
        {
          id: "2.7.1",
          description: "Online Students Satisfaction Survey",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criteria 3 - Research, Innovations and Extension",
      metrics: [
        {
          id: "3.1.1",
          description:
            "Grants Received From Government / Non-Government Agencies for Research Projects",
          downloadLink: "#",
        },
        {
          id: "3.2.2",
          description:
            "Workshops / Seminars / Conference Conducted On IPR, Research Methodology and Entrepreneurship",
          downloadLink: "#",
        },
        {
          id: "3.3.1",
          description: "Research Papers",
          downloadLink: "#",
        },
        {
          id: "3.3.2",
          description: "Books / Chapter / Conference Papers Published",
          downloadLink: "#",
        },
        {
          id: "3.4.3",
          description:
            "Extension and Outreach Programmes Conducted Through NSS / NCC",
          downloadLink: "#",
        },
        {
          id: "3.5.1",
          description: "MoU’s and Collaborations",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criterion 4 - Infrastructure and Learning Resources",
      metrics: [
        {
          id: "4.1.2",
          description:
            "Expenditure, Excluding Salary for Infrastructure Augmentation",
          downloadLink: "#",
        },
        {
          id: "4.3.2",
          description: "Student – Computer Ratio",
          downloadLink: "#",
        },
        {
          id: "4.4.1",
          description: "Expenditure Incurred on Maintenance of Infrastructure",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criterion 5 - Student Support and Progression",
      metrics: [
        {
          id: "5.1.1",
          description:
            "Scholarships, Freeships (Institution / Government / Non-Government)",
          downloadLink: "#",
        },
        {
          id: "5.1.2",
          description: "Capacity Building and Skills Enhancement Initiatives",
          downloadLink: "#",
        },
        {
          id: "5.1.3",
          description:
            "Students Benefitted By Guidance for Competitive Examinations and Career Counselling",
          downloadLink: "#",
        },
        {
          id: "5.1.4",
          description: "Student Grievances / Sexual Harassment / Ragging Cases",
          downloadLink: "#",
        },
        {
          id: "5.2.1",
          description:
            "Placement of Outgoing Students and Students Progressing to Higher Education",
          downloadLink: "#",
        },
        {
          id: "5.2.2",
          description:
            "Students Qualifying in State / National / International Level Examinations",
          downloadLink: "#",
        },
        {
          id: "5.3.1",
          description:
            "Awards / Medals for Outstanding Performance in Sports / Cultural Activities",
          downloadLink: "#",
        },
        {
          id: "5.3.2",
          description: "Number of Sports and Cultural Programs",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criterion 6 - Governance, Leadership and Management",
      metrics: [
        {
          id: "6.2.2",
          description: "Implementation of e-governance in Areas of Operation",
          downloadLink: "#",
        },
        {
          id: "6.3.2",
          description:
            "Teachers Provided with Financial Support (Conference / Workshop and Professional Membership)",
          downloadLink: "#",
        },
        {
          id: "6.3.3",
          description:
            "Participation of Teaching and Non-Teaching Staff in Professional Development Programs",
          downloadLink: "#",
        },
        {
          id: "6.5.2",
          description: "Quality Assurance Initiatives",
          downloadLink: "#",
        },
      ],
    },
    {
      title: "Criterion 7 – Institutional Values and Best Practices",
      metrics: [
        {
          id: "7.1.2",
          description:
            "Alternate Sources of Energy, Water Conservation Facilities, Green Campus Initiatives and Disabled-Friendly Environment",
          downloadLink: "#",
        },
        {
          id: "7.1.3",
          description: "Quality Audits (Green, Environment and Energy)",
          downloadLink: "#",
        },
      ],
    },
  ];

  document.title = "SSR";
  const serviceData = [
    {
      name: "Notice",
      // link: notice,
    },
    {
      name: "Office Circular",
      // link: officeCircular,
    },
  ];
  return (
    // <ScrollToHeading offset={140}>
    <div className="">
      <div className="">
        <HeaderBanner
          bgImage="AboutBanner"
          title="SSR"
          currentPage="NAAC Documents"
        />

        <div className="bannerBotttom h-[3.5rem] w-full"></div>
      </div>
      {/* <div className="mt-20 flex justify-center pb-10 text-3xl font-bold md:text-5xl">
        <h1 className=" border-l-2 border-[#32727A] pl-4 text-[#081C3A]">
          Criterion I (SSR CLICK HERE )<span className="pl-1 text-[#32727A]"></span>
        </h1>
      </div>
      <div className="criterion-table">
      <table width="1000px" align="center" border={2} id="table-alt">
        <tbody><tr bgcolor="cornflowerblue">
            <td><div align="center"><strong><font color="white">Criteria Number</font></strong></div></td>
            <td><div align="center"><strong><font color="white"><span style={{fontSize: '16px'}}>Criteria Name</span></font></strong></div></td>
            <td><div align="center"><strong><font color="white">Link to Download&nbsp;&nbsp;<img src="images/2.gif" width={25} height={25} /></font></strong></div></td>
          </tr>
          <tr bgcolor="cornflowerblue">
            <td><div align="center"><strong><font color="white"></font></strong></div></td>
            <td><div align="center"><strong><font color="white"><span style={{fontSize: '16px'}}>Criterion 1 – Curricular Aspects</span></font></strong></div></td>
            <td><div align="center"><strong><font color="white"></font></strong></div></td>
          </tr>
          <tr><td><div align="center">1.1</div></td> <td align="center" valign="middle"><font color="blue"><b>Curricular Planning and Implementation
</b></font></td><td>&nbsp;</td></tr>

          
          <tr><td><div align="center">1.1.1</div></td> <td align="left" valign="middle">The Institution ensures effective curriculum planning and delivery through a well-planned and documented process including Academic calendar and conduct of continuous internal Assessment. </td><td><a href="https://www.scholastic.com/hpread/HP_Book1_Chapter_Excerpt.pdf" target="_blank&quot;">Click Here</a></td></tr>
         
          <tr><td><div align="center">1.2</div></td> <td align="center" valign="middle"><font color="blue"><b>Academic Flexibility</b></font></td><td /></tr>
          <tr><td><div align="center">1.2.1</div></td> <td align="left" valign="middle">Number of Add on /Certificate/Value added programs offered during the last five years </td><td /></tr>
          <tr><td><div align="center">1.2.2</div></td> <td align="left" valign="middle">Percentage of students enrolled in Certificate/ Add-on/Value added programs as against the total number of students during the last five years 
</td><td><a href="C1/C1_2_1_1.pdf" target="_blank&quot;">Click Here</a></td></tr>
          <tr><td><div align="center">1.3</div></td> <td align="center" valign="middle"><font color="blue"><b>Curriculum Enrichment (30)</b></font></td><td>&nbsp;</td></tr>
          <tr><td><div align="center">1.3.1</div></td> <td align="left" valign="middle">Institution integrates crosscutting issues relevant to Professional Ethics,  Gender, Human Values, Environment and Sustainability into the Curriculum</td><td><a href="C1/C1_3_1.pdf" target="_blank">Click Here</a></td></tr>
          <tr><td><div align="center">1.3.2</div></td> <td align="left" valign="middle">Percentage of students undertaking project work/field work/ internships (Data for the latest completed academic year) </td><td>&nbsp;</td></tr>
          <tr><td><div align="center">&nbsp;</div></td> <td align="left" valign="middle">i) Programme / Curriculum/ Syllabus of the courses</td><td><a href="C1/C1_3_2_1_i.pdf" target="_blank">Click Here</a></td></tr>
         
          <tr><td><div align="center">1.4</div></td> <td align="center" valign="middle"><font color="blue"><b>Feedback System (20)

</b></font></td><td>&nbsp;</td></tr>

          <tr><td><div align="center">1.4.1</div></td> <td align="left" valign="middle">Institution obtains feedback on the academic performance and ambience of the institution from various stakeholders, such as Students, Teachers, Employers, Alumni etc. and action taken report on the feedback is made available on  institutional website(Yes or No)</td><td>&nbsp;</td></tr>
          <tr bgcolor="cornflowerblue">
            <td><div align="center"><strong><font color="white"></font></strong></div></td>
            <td><div align="center"><strong><font color="white"><span style={{fontSize: '16px'}}>Criterion 2 – Teaching- Learning and Evaluation (350)
</span></font></strong></div></td>
            <td><div align="center"><strong><font color="white"></font></strong></div></td>
          </tr>
          <tr><td><div align="center">2.1</div></td> <td align="center" valign="middle"><font color="blue"><b>Curricular Planning and Implementation
</b></font></td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.1.1</div></td> <td align="left" valign="middle">Enrolment percentage </td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.1.2</div></td> <td align="left" valign="middle">Percentage of seats filled against seats reserved for various categories (SC, ST, OBC, Divyangjan, etc. as per applicable reservation policy during the last five years
(Exclusive of supernumerary seats) </td><td>&nbsp;</td></tr>

<tr><td><div align="center">2.2</div></td> <td align="center" valign="middle"><font color="blue"><b>Student Teacher Ratio (40)

</b></font></td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.2.1</div></td> <td align="left" valign="middle">Student – Full time Teacher Ratio
(Data for the latest completed academic year)	</td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.2.2</div></td> <td align="left" valign="middle">Percentage of students enrolled in Certificate/ Add-on/Value added programs as against the total number of students during the last five years 
(Data for the latest completed academic year)	</td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.2</div></td> <td align="center" valign="middle"><font color="blue"><b>Student Teacher Ratio (40)

</b></font></td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.2.1</div></td> <td align="left" valign="middle">Student – Full time Teacher Ratio
(Data for the latest completed academic year)	</td><td>&nbsp;</td></tr>
<tr><td><div align="center">2.2.2</div></td> <td align="left" valign="middle">Percentage of students enrolled in Certificate/ Add-on/Value added programs as against the total number of students during the last five years 
(Data for the latest completed academic year)	</td><td>&nbsp;</td></tr>

    
           </tbody></table>
        </div>
      <div className="max-w-[1300px] mx-auto">
        <div className="primary-bg-color p-3 rounded-lg text-blue-800 text-xl font-semibold hover:bg-[#32727a] cursor-pointer m-2 mb-5">
          + Links
          <a href={HeaderBanner1} target="_blank" rel="noreferrer">LINK1</a>
        </div>
        <ul className="list-disc ml-12 text-lg">
          {serviceData?.map((value) => (
            <li key={value} className="py-2 hover:text-[#32727a] font-semibold">
              <a href={value?.link} target="_blank" rel="noreferrer">
                <span className="flex">
                  {value?.name}
                  <FaLink className="item-center flex mt-1 ml-2" />
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div> */}

      <div className="px-3 md:px-5 max-w-[1300px] mx-auto">
        <div className="primary-color xs container mx-4 mt-8 w-72 border-l-2 border-[#32727a] pl-3 text-3xl font-bold xxs:w-[90%] xs:mx-auto xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl xl:w-[65%] 2xl:w-[50%] xl:ml-[30rem] lg:mb-10">
          NAAC - <span className="secondary-color"> Important Documents</span>
        </div>

        <div className="container mt-2 xs:mt-4 text-justify lg:mt-5 xl:mb-[5rem]">
          <div className="table-responsive overflow-x-auto">
            <table className="table table-hover table-bordered w-full border border-gray-400">
              <thead>
                <tr className="bg-blue-900 text-white text-center">
                  <th className="py-3 pl-4 pr-4 border">Sr No</th>
                  <th className="py-3 pl-4 pr-4 border">DESCRIPTION</th>
                  <th className="py-3 pl-4 pr-4 border">DOWNLOAD</th>
                </tr>
              </thead>
              <tr className="hover:bg-gray-100 border">
                <td className="py-3 pl-4 pr-4 border text-center font-bold">
                  1
                </td>
                <td className="py-3 pl-4 pr-4 border  font-bold">
                  Self Declaration
                </td>
                <td className="py-3 pl-4 pr-4 border text-center font-bold">
                  <a href="#" className="text-blue-800 hover:text-blue-900">
                    VIEW
                  </a>
                </td>
              </tr>
              <tr className="hover:bg-gray-100 border">
                <td className="py-3 pl-4 pr-4 border text-center font-bold">
                  2
                </td>
                <td className="py-3 pl-4 pr-4 border  font-bold">
                  Undertaking
                </td>
                <td className="py-3 pl-4 pr-4 border text-center font-bold">
                  <a href="#" className="text-blue-800 hover:text-blue-900">
                    VIEW
                  </a>
                </td>
              </tr>
              <tr className="hover:bg-gray-100 border">
                <td className="py-3 pl-4 pr-4 border text-center font-bold">
                  3
                </td>
                <td className="py-3 pl-4 pr-4 border  font-bold">SSR</td>
                <td className="py-3 pl-4 pr-4 border text-center font-bold">
                  <a href="#" className="text-blue-800 hover:text-blue-900">
                    VIEW
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="container mt-2 mb-8 xs:mt-4 text-justify lg:mt-5 xl:mb-[5rem]">
          {NAAC?.map((section, index) => (
            <div key={index} className="text-blue-900 p-4 mb-4 rounded-lg">
              <h2 className="text-2xl font-bold mb-2">{section.title}</h2>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-hover table-bordered w-full border border-gray-400">
                  <thead>
                    <tr className="bg-blue-900 text-white text-center">
                      <th className="py-3 pl-4 pr-4 border">METRIC</th>
                      <th className="py-3 pl-4 pr-4 border">DESCRIPTION</th>
                      <th className="py-3 pl-4 pr-4 border">DOWNLOAD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.metrics.map((metric, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 border text-black font-bold text-center"
                      >
                        <td className="py-3 pl-4 pr-4 border">{metric.id}</td>
                        <td className="py-3 pl-4 pr-4 border text-left">
                          {metric.description}
                        </td>
                        <td className="py-3 pl-4 pr-4 border">
                          <a
                            href={metric.downloadLink}
                            className="text-blue-800 hover:text-blue-900"
                          >
                            VIEW
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    // </ScrollToHeading>
  );
};
export default SSR;
