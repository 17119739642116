export const data = [
  {
    image: require("../../../assets/images/Picture6.jpg"),
    name: " Harpreet Kaur",
    education: "Full Stack Developer",
    title:"",
    id: 114,
  },
  {
    image: require("../../../assets/images/Picture7.jpg"),
    name: "Ravinder Singh",
    education: "Full stack Developer",
    title:"",
    id: 115,
  },
  {
    image: require("../../../assets/images/Picture8.jpg"),
    name: " Anjali",
    education: "Python Developer cum Technical writer",
    title:" ECPL & Intellipaat",
    id: 116,
  },
  {
    image: require("../../../assets/images/Picture9.jpg"),
    name: "Simranjeet kaur ",
    education: " Intellipaat, Bangalore Designation-Research Analyst",
    title:"",
    id: 117,
  },
  {
    image: require("../../../assets/images/Picture10.jpg"),
    name: "Keshav Kanda",
    education: " Software Trainee",
    title:"Solitaire Infosys",
    id: 118,
  },
  {
    image: require("../../../assets/images/Picture11.png"),
    name: "Ranjit Kaur",
    education: "Software Trainee",
    title:"Solitaire Infosys Pvt. Ltd.",
    id: 119,
  },
  {
    image: require("../../../assets/images/Picture12.png"),
    name: "Anmol Punj",
    education: "Jr.Software developer",
    title:"Delente Technologies",
    id: 120,
  },
  {
    image: require("../../../assets/images/Picture14.jpg"),
    name: "Gurpreet Kaur",
    education: "Junior Software Engineer",
    title:"Netsmartz ",
    id: 121,
  },
  {
    image: require("../../../assets/images/Picture16.jpg"),
    name: "Yaskaran",
    education: "Trainee - Software Engineer/Web Developer",
    title:"Relinns Technologies",
    id: 122,
  },
  {
    image: require("../../../assets/images/Picture17.jpg"),
    name: "Sourav Joshi",
    education: "Research Analyst",
    title:"Intellipaat, Bangalore ",
    id: 123,
  },
  {
    // image: '/images/pankajkumar.jpg',
    image: require("../../../assets/images/pankajkumar.jpg"),
    name: "Pankaj Kumar",
    education: "B.Tech(CSE)",
    title:"SV Footwear",
    id: 1,
  },
  {
    image: require("../../../assets/images/Anamika-Sharma.jpg"),
    name: "Anamika Sharma",
    education: "B.Tech(CSE)",
    title:"Wipro",
    id: 2,
  },
  {
    image: require("../../../assets/images/Navdeep-Kaur.jpg"),
    name: "Navdeep kaur",
    education: "B.Tech(CSE)",
    title:"SEASIA INFOTECH & SEASIA INFOTECH",
    id: 3,
  },
  {
    image: require("../../../assets/images/komal-.jpg"),
    name: "Komal",
    education: "B.Tech(CSE)",
    title:"Pepcoding",
    id: 4,
  },
  {
    image: require("../../../assets/images/Navjot-Kaur.jpg"),
    name: "Navjot kaur",
    education: "B.Tech(CSE)",
    title:"Seasia",
    id: 5,
  },
  {
    image: require("../../../assets/images/Nitika.jpg"),
    name: "Nikita",
    education: "B.Tech(CSE)",
    title:"Pepcoding",
    id: 6,
  },
  {
    image: require("../../../assets/images/Ranjna.jpg"),
    name: "Ranjna",
    education: "B.Tech(CSE)",
    title:"TECH MAHINDRA",
    id: 7,
  },
  {
    image: require("../../../assets/images/Shabnam.jpg"),
    name: "Shabnam",
    education: "B.Tech(CSE)",
    title:"RACLOOP TECHNOLOGIES PVT. LTD.",
    id: 8,
  },
  {
    image: require("../../../assets/images/AjayParmar.jpg"),
    name: "Ajay Parmar",
    education: "B.Tech(CSE)",
    title:"SEASIA INFOTECH",
    id: 9,
  },
  {
    image: require("../../../assets/images/RajpreetSingh.jpg"),
    name: "Rajpreet Singh",
    education: "B.Tech(CSE)",
    title:"SEASIA INFOTECH",
    id: 10,
  },
  {
    image: require("../../../assets/images/GurvinderSingh1.jpg"),
    name: "Gurvinder Singh",
    education: "B.Tech(CSE)",
    title:"SEASIA INFOTECH",
    id: 11,
  },
 
  {
    image: require("../../../assets/images/HarinderKaur1.jpg"),
    name: "Harinder Kaur",
    education: "B.Tech(CSE)",
    title:"SEASIA INFOTECH",
    id: 13,
  },
  {
    image: require("../../../assets/images/Rahulyadav1.jpg"),
    name: "Rahul Yadav",
    education: "B.Tech(CSE)",
    title:"B.Tech. (CSE)BEBO TECHNOLOGY AND SEASIA INFOTECH",
    id: 14,
  },
  {
    image: require("../../../assets/images/DeepakPatel1.jpg"),
    name: "Deepak Patel",
    education: "B.Tech(CSE)",
    title:"RTS CORPORATION",
    id: 15,
  },
  {
    image: require("../../../assets/images/HargunRana.jpg"),
    name: "Hargun Rana",
    education: "B.Tech(CSE)",
    title:"AMAZON",
    id: 16,
  },
  {
    image: require("../../../assets/images/NavneetKaur.jpg"),
    name: "Navneet kaur",
    education: "B.Tech(CSE)",
    title:"ECPL",
    id: 18,
  },
  {
    image: require("../../../assets/images/PawandeepSingh.jpg"),
    name: "Pawandeep singh",
    education: "B.Tech(CSE)",
    title:"NUCLEUS SOFTWARE AND ECPL",
    id: 19,
  },
  {
    image: require("../../../assets/images/RichuMehta1.jpg"),
    name: "Richu Mehta",
    education: "B.Tech(CSE)",
    title:"PEPCODING EDUCATION PVT. LTD.",
    id: 20,
  },
  {
    image: require("../../../assets/images/kushpreetk.jpg"),
    name: "Kushpreet Kaur",
    education: "B.Tech(CSE)",
    title:"IP SERVICES PRIVATE LIMITED",
    id: 21,
  },
  {
    image: require("../../../assets/images/namarta.jpg"),
    name: "Namarta",
    education: "B.Tech(CSE)",
    title:"IP SERVICES PRIVATE LIMITED",
    id: 22,
  },

  {
    image: require("../../../assets/images/jatin.jpg"),
    name: "Jatin",
    education: "B.Tech(CSE)",
    title:"ChicMic",
    id: 23,
  },
  {
    image: require("../../../assets/images/rahuljindal001.jpg"),
    name: "Rahul Jindal",
    education: "B.Tech(CSE)",
    title:"Zapbuild",
    id: 24,
  },
  {
    image: require("../../../assets/images/MAMTA.jpg"),
    name: "Mamta Kumari",
    education: "B.Tech(CSE)",
    title:"SACH TECH",
    id: 25,
  },
  {
    image: require("../../../assets/images/Ramandeepkaur.jpg"),
    name: "Ramandeep kaur",
    education: "B.Tech(CSE)",
    title:"INIZ Solutions",
    id: 26,
  },
  {
    image: require("../../../assets/images/Jyotisharma.jpg"),
    name: "Jyoti sharma",
    education: "B.Tech(CSE)",
    title:"INIZ Solutions",
    id: 27,
  },
  {
    image: require("../../../assets/images/rosy.jpg"),
    name: "Rosy Mehta",
    education: "B.Tech(CSE)",
    title:"Netsmartz Limited",
    id: 28,
  },
  {
    image: require("../../../assets/images/manpreet002.jpg"),
    name: "Manpreet Kaur",
    education: "B.Tech(CSE)",
    title:"BYJU'S",
    id: 29,
  },
  {
    image: require("../../../assets/images/pl-04.jpg"),
    name: "Hitesh",
    education: "B.Tech(CSE)",
    title:"Advance Solutions & Cognitier Technologies",
    id: 30,
  },
  {
    image: require("../../../assets/images/aakriti001.jpg"),
    name: "Aakriti",
    education: "B.Tech(ECE)",
    title:"Mphasis & Advance Solutions",
    id: 31,
  },
  {
    image: require("../../../assets/images/manpreet001.jpg"),
    name: "Manpreet kaur",
    education: "B.Tech(CSE)",
    title:"Advance Solutions",
    id: 32,
  },
  {
    image: require("../../../assets/images/sunidhi001.jpg"),
    name: "Sunidhi",
    education: "B.Tech(ECE)",
    title:"Advance Solutions",
    id: 33,
  },
  {
    image: require("../../../assets/images/anjali001.jpg"),
    name: "Anjali",
    education: "B.Tech(CSE)",
    title:"Advance Solutions",
    id: 34,
  },
  {
    image: require("../../../assets/images/ritikapathak.jpg"),
    name: "Ritika Pathak",
    education: "B.Tech(CSE)",
    title:"Seasia",
    id: 35,
  },
  {
    image: require("../../../assets/images/anamika.jpg"),
    name: "Anamika",
    education: "B.Tech(CSE)",
    title:"Seasia",
    id: 36,
  },
  {
    image: require("../../../assets/images/ritikchugh.jpg"),
    name: "Ritik Chugh",
    education: "B.Tech(CSE)",
    title:"Seasia",
    id: 37,
  },
  {
    image: require("../../../assets/images/muskangulati.jpg"),
    name: "Muskan Gulati",
    education: "B.Tech(CSE)",
    title:"Smart Data Enterprises",
    id: 38,
  },
  {
    image: require("../../../assets/images/amanpreetkaur.jpg"),
    name: "Amanpreet Kaur",
    education: "B.Tech(CSE)",
    title:"BEBO technology",
    id: 39,
  },
  {
    image: require("../../../assets/images/pardeepkaur.jpg"),
    name: "Pardeep Kaur",
    education: "B.Tech(CSE)",
    title:"Tele performance technology",
    id: 40,
  },
  {
    image: require("../../../assets/images/gurpinderkaur.jpg"),
    name: "Gurpinder Kaur",
    education: "B.Tech(CSE)",
    title:"ADVANCE solutions",
    id: 41,
  },
  {
    image: require("../../../assets/images/rubykala.jpg"),
    name: "Ruby Kala",
    education: "B.Tech(CSE)",
    title:"BEBO Technology & Seasia",
    id: 42,
  },
  {
    image: require("../../../assets/images/jagjot.jpg"),
    name: "Jagjot Singh",
    education: "B.Tech(ECE)",
    title:"SUKHJIT STARCH, PHAGWARA",
    id: 43,
  },
  {
    image: require("../../../assets/images/divyansh.jpg"),
    name: "Divyansh Kaushal",
    education: "B.Tech(CSE)",
    title:"GNA Gears",
    id: 44,
  },

  {
    image: require("../../../assets/images/rahulkumar.jpg"),
    name: "Rahul Kumar",
    education: "B.Tech(CSE)",
    title:"GNA Gears",
    id: 45,
  },

  {
    image: require("../../../assets/images/sarabjeet.jpg"),
    name: "Sarbjeet",
    education: "B.Tech(ECE)",
    title:"GNA Gears",
    id: 46,
  },
  {
    image: require("../../../assets/images/prabhjotsingh-1.jpg"),
    name: "Prabhjot singh",
    education: "B.Tech(ECE)",
    title:"GNA Gears",
    id: 47,
  },
  {
    image: require("../../../assets/images/daljitsingh.jpg"),
    name: "Daljit singh",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 48,
  },
  {
    image: require("../../../assets/images/devinderpal.jpg"),
    name: "Devinderpal singh",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 49,
  },
  {
    image: require("../../../assets/images/gurpreetsingh.jpg"),
    name: "Gurpreet singh",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 50,
  },
  {
    image: require("../../../assets/images/happykumar.jpg"),
    name: "Happy Kumar",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 51,
  },
  {
    image: require("../../../assets/images/lalitkumar.jpg"),
    name: "Lalit Kumar",
    education: "B.Tech(ME)",
    title:"Pankaj Kumar",
    id: 52,
  },
  {
    image: require("../../../assets/images/pankajkumar.jpg"),
    name: "Pankaj Kumar",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 53,
  },
  {
    image: require("../../../assets/images/parampreet.jpg"),
    name: "Parampreet",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 54,
  },
  {
    image: require("../../../assets/images/vijinderkumar.jpg"),
    name: "Vijinder Kumar",
    education: "B.Tech(ME)",
    title:"SV Footwear",
    id: 55,
  },
  {
    image: require("../../../assets/images/shivam.jpg"),
    name: "Shivam",
    education: "B.Tech(ME)",
    title:"Krome Dispense",
    id: 56,
  },
  {
    image: require("../../../assets/images/jatinduggal.jpg"),
    name: "Jatin Duggal",
    education: "B.Tech(ME)",
    title:"Krome Dispense",
    id: 57,
  },
  {
    image: require("../../../assets/images/harpreetsingh.jpg"),
    name: "Harpreet Singh Gill",
    education: "B.Tech(ME)",
    title:"Krome Dispense",
    id: 58,
  },
  {
    image: require("../../../assets/images/shubhamsingh.jpg"),
    name: "Shubham Singh",
    education: "B.Tech(ME)",
    title:"Krome Dispens",
    id: 59,
  },
  {
    image: require("../../../assets/images/arunkumar.jpg"),
    name: "Arun Kumar",
    education: "B.Tech(ME)",
    title:"Krome Dispense",
    id: 60,
  },
  {
    image: require("../../../assets/images/1538475.jpg"),
    name: "TWINKLE PUNJ",
    education: "B.Tech(ME)",
    title:"WIPRO",
    id: 61,
  },
  {
    image: require("../../../assets/images/1538442.jpg"),
    name: "Kashish Kumar",
    education: "B.Tech(CSE)",
    title:"WIPRO ",
    id: 62,
  },
  {
    image: require("../../../assets/images/rishabhjain-1.jpg"),
    name: "Rishabh Jain",
    education: "B.Tech(CSE)",
    title:"Cogniter Technologies",
    id: 63,
  },
  {
    image: require("../../../assets/images/ritika001.jpg"),
    name: "Ritika",
    education: "B.Tech(CSE)",
    title:"Radsol Design PVT. LTD.",
    id: 64,
  },
  {
    image: require("../../../assets/images/anusaini.jpg"),
    name: "Anu Saini",
    education: "B.Tech(CSE)",
    title:"GoTeSo Pvt. Ltd.",
    id: 65,
  },
  {
    image: require("../../../assets/images/jaspreetkaur.jpg"),
    name: "Jaspreet Kaur",
    education: "B.Tech(ECE)",
    title:"GoTeSo Pvt. Ltd.",
    id: 66,
  },
  {
    image: require("../../../assets/images/nitin.jpg"),
    name: "Nitin",
    education: "B.Tech(ECE)",
    title:"GoTeSo Pvt. Ltd",
    id: 67,
  },
  {
    image: require("../../../assets/images/narinderkaur.jpg"),
    name: "Narinder Kaur",
    education: "B.Tech(ECE)",
    title:"CINIF Technologies Pvt. Ltd.",
    id: 68,
  },
  {
    image: require("../../../assets/images/nitish.jpg"),
    name: "Nitish",
    education: "B.Tech(ECE)",
    title:"CINIF Technologies Pvt. Ltd.",
    id: 69,
  },

  {
    image: require("../../../assets/images/shifali.jpg"),
    name: "Shifali",
    education: "B.Tech(ECE)",
    title:"CINIF Technologies Pvt. Ltd.",
    id: 70,
  },

  {
    image: require("../../../assets/images/divyamjain.jpg"),
    name: "Divyam Jain",
    education: "B.Tech(ECE)",
    title:"CINIF Technologies Pvt. Ltd.",
    id: 71,
  },

  {
    image: require("../../../assets/images/1449600.jpg"),
    name: "Suraj",
    education: "B.Tech(ECE)",
    title:"OATI",
    id: 72,
  },

  {
    image: require("../../../assets/images/1538435.jpg"),
    name: "Gaurav Badhan",
    education: "B.Tech(ECE)",
    title:"EDUCATION CULTURE PVT. LTD.",
    id: 73,
  },
  {
    image: require("../../../assets/images/1538440.jpg"),
    name: "Kajal Satija",
    education: "B.Tech(CSE)",
    title:"TCS",
    id: 74,
  },
  {
    image: require("../../../assets/images/karna.jpg"),
    name: "Karuna",
    education: "B.Tech(ECE)",
    title:"GOTESO",
    id: 75,
  },
  {
    image: require("../../../assets/images/1538448.jpg"),
    name: "Manmeet Kaur",
    education: "B.Tech(CSE)",
    title:"DEFACTO INFOTECH PVT. LTD.",
    id: 76,
  },
  {
    image: require("../../../assets/images/1538450.jpg"),
    name: "Mrinaal",
    education: "B.Tech(CSE)",
    title:"IMPINGE SOLUTIONS",
    id: 77,
  },
  {
    image: require("../../../assets/images/1538455.jpg"),
    name: "Paras Dua",
    education: "B.Tech(CSE)",
    title:"TCS",
    id: 78,
  },
  {
    image: require("../../../assets/images/1538457.jpg"),
    name: "Priyanka Bhutani",
    education: "B.Tech(CSE)",
    title:"EDUCATION CULTURE PVT. LTD.",
    id: 79,
  },
  {
    image: require("../../../assets/images/poojarani.jpg"),
    name: "Pooja Rani",
    education: "B.Tech(CSE)",
    title:"EDUCATION CULTURE PVT. LTD.",
    id: 80,
  },
  {
    image: require("../../../assets/images/1538469.jpg"),
    name: "Baljeet Singh",
    education: "B.Tech(CSE)",
    title:"PROMATICS",
    id: 81,
  },
  {
    image: require("../../../assets/images/1538469a.jpg"),
    name: "Sukhwinder Singh",
    education: "B.Tech(CSE)",
    title:"DEFACTO INFOTECH PVT. LTD.",
    id: 82,
  },
  {
    image: require("../../../assets/images/1538474.jpg"),
    name: "Sweksha Shukla",
    education: "B.Tech(CSE)",
    title:"EDUCATION CULTURE PVT. LTD.",
    id: 83,
  },
  {
    image: require("../../../assets/images/1538630.jpg"),
    name: "Rupinder Singh",
    education: "B.Tech(CSE)",
    title:"OATI",
    id: 84,
  },
  {
    image: require("../../../assets/images/1632744.jpg"),
    name: "Harjot Kaur",
    education: "B.Tech(CSE)",
    title:"SOLITAIRE INFOSYS",
    id: 85,
  },
  {
    image: require("../../../assets/images/1632750.jpg"),
    name: "Ketan Sharma",
    education: "B.Tech(CSE)",
    title:"COGNITER TECHNOLOGIES",
    id: 86,
  },
  {
    image: require("../../../assets/images/1632755.jpg"),
    name: "Monika",
    education: "B.Tech(CSE)",
    title:"Monika",
    id: 87,
  },
  {
    image: require("../../../assets/images/amritpal.jpg"),
    name: "Amritpal kaur",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 88,
  },
  {
    image: require("../../../assets/images/arun.jpg"),
    name: "Arun",
    education: "B.Tech(CSE)", 
    title:"IBM",
    id: 89,
  },
  {
    image: require("../../../assets/images/bhanupartap.jpg"),
    name: "Bhanu Partap",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 90,
  },
  {
    image: require("../../../assets/images/hargun.jpg"),
    name: "Hargun",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 91,
  },
  {
    image: require("../../../assets/images/harinder.jpg"),
    name: "Harinder",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 92,
  },
  {
    image: require("../../../assets/images/jatin_1.jpg"),
    name: "Jatin",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 93,
  },
  {
    image: require("../../../assets/images/karandeep.jpg"),
    name: "Karandeep",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 94,
  },
  {
    image: require("../../../assets/images/komal.jpg"),
    name: "Komal",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 95,
  },
  {
    image: require("../../../assets/images/navdeepkaur.jpg"),
    name: "Navdeep Kaur",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 96,
  },
  {
    image: require("../../../assets/images/pawandeepsingh_1.jpg"),
    name: "Pawandeep",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 97,
  },
  {
    image: require("../../../assets/images/richu.jpg"),
    name: "Richu",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 98,
  },
  {
    image: require("../../../assets/images/samritivashisht.jpg"),
    name: "Samriti Vashisht",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 100,
  },
  {
    image: require("../../../assets/images/sharanjitkaur.jpg"),
    name: "Sharajit Kaur",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 101,
  },
  {
    image: require("../../../assets/images/shivamsharma.jpg"),
    name: "Shivam Sharma",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 102,
  },
  {
    image: require("../../../assets/images/jaspreet.jpg"),
    name: "Jaspreet",
    education: "B.Tech(CSE)",
    title:"IBM",
    id: 103,
  },
  {
    image: require("../../../assets/images/kamal.jpg"),
    name: "Kamal",
    education: "B.Tech(ECE)",
    title:"IBM",
    id: 104,
  },
  {
    image: require("../../../assets/images/khushpreetkaur.jpg"),
    name: "Khushpreet kaur",
    education: "B.Tech(ECE)",
    title:"IBM",
    id: 105,
  },
  {
    image: require("../../../assets/images/lavankshi.jpg"),
    name: "Lavankshi",
    education: "B.Tech(ECE)",
    title:"IBM",
    id: 106,
  },
  {
    image: require("../../../assets/images/simranbhagat.jpg"),
    name: "Simran Bhagat",
    education: "B.Tech(ECE)",
    title:"IBM",
    id: 107,
  },
  {
    image: require("../../../assets/images/sourav.jpg"),
    name: "Sourav",
    education: "B.Tech(ECE)",
    title:"IBM",
    id: 108,
  },
  {
    image: require("../../../assets/images/anmol.jpg"),
    name: "Anmoldeep Saini",
    education: "Associate Software developer",
    title:" Chicmic ",
    id: 109,
  },
  {
    image: require("../../../assets/images/Picture2.jpg"),
    name: "Shashank Malhotra",
    education: "",
    title:"Education Culture Pvt Ltd (ECPL)",
    id: 110,
  },
  {
    image: require("../../../assets/images/Picture3.jpg"),
    name: "Akshay Kalia",
    education: "Frontend Developer",
    title:"",
    id: 111,
  },
  {
    image: require("../../../assets/images/Picture4.jpg"),
    name: "Anoop Kumar",
    education: "Full Stack Developer",
    title:"",
    id: 112,
  },
  {
    image: require("../../../assets/images/Picture5.jpg"),
    name: "Jaspreet Singh",
    education: "Full Stack Developer",
    title:"",
    id: 113,
  },

  // {
  //   // image: require("../../../assets/images/"),
  //   name: "",
  //   education: "",
  //   title:"",
  //   id: 124,
  // },
  // {
  //   // image: require("../../../assets/images/"),
  //   name: "",
  //   education: "",
  //   title:"",
  //   id: 125,
  // },
  // {
  //   // image: require("../../../assets/images/"),
  //   name: "",
  //   education: "",
  //   title:"",
  //   id: 126,
  // },
  // {
  //   // image: require("../../../assets/images/"),
  //   name: "",
  //   education: "",
  //   title:"",
  //   id: 127,
  // },
  // {
  //   // image: require("../../../assets/images/"),
  //   name: "",
  //   education: "",
  //   title:"",
  //   id: 128,
  // },

];
