export const arr1 = [
  {
    id: 1,
    img: require("../../../assets/images/18052022-4.jpg"),
  },
  {
    id: 2,
    img: require("../../../assets/images/18052022-2.jpg"),
  },
  {
    id: 3,
    img: require("../../../assets/images/18052022-1.jpg"),
  },
  {
    id: 4,
    img: require("../../../assets/images/18052022-3.jpg"),
  },
  {
    id: 5,
    img: require("../../../assets/images/18052022-5.jpg"),
  },
];
export const arr2 = [
  {
    id: 1,
    img: require("../../../assets/images/ME-1.png"),
  },
  {
    id: 2,
    img: require("../../../assets/images/ME-1.png"),
  },
  {
    id: 3,
    img: require("../../../assets/images/ME-1.png"),
  },
  {
    id: 4,
    img: require("../../../assets/images/ME-1.png"),
  },
  {
    id: 5,
    img: require("../../../assets/images/ME-1.png"),
  },
];
export const arr3 = [
  {
    id: 1,
    img: require("../../../assets/images/PUNCOM-2.png"),
  },
  {
    id: 2,
    img: require("../../../assets/images/PUNCOM-1.png"),
  },
  {
    id: 3,
    img: require("../../../assets/images/PUNCOM-3.png"),
  },
  {
    id: 4,
    img: require("../../../assets/images/PUNCOM-4.png"),
  },
  {
    id: 5,
    img: require("../../../assets/images/PUNCOM-5.png"),
  },
];
export const arr4 = [
  {
    id: 1,
    img: require("../../../assets/images/AS-1.png"),
  },
  {
    id: 2,
    img: require("../../../assets/images/AS-2.png"),
  },
  {
    id: 3,
    img: require("../../../assets/images/AS-3.png"),
  },
  {
    id: 4,
    img: require("../../../assets/images/AS-4.png"),
  },
];
export const arr5 = [
  {
    id: 1,
    img: require("../../../assets/images/Civil-1.png"),
  },
  {
    id: 2,
    img: require("../../../assets/images/Civil-2.png"),
  },
  {
    id: 3,
    img: require("../../../assets/images/Civil-3.png"),
  },
];
export const arr6 = [
  {
    id: 1,
    img: require("../../../assets/images/VisitIT1.jpg"),
  },
  {
    id: 2,
    img: require("../../../assets/images/VisitIT2.jpg"),
  },
];
export const arr7 = [
  {
    id: 1,
    img: require("../../../assets/images/VisitMg1.jpg"),
  },
  {
    id: 2,
    img: require("../../../assets/images/VisitMg2.jpg"),
  },
];

export const arr8 = [
  {
    id: 1,
    img: require("../../../assets/images/CE-1.png"),
  },
  {
    id: 2,
    img: require("../../../assets/images/CE-2.png"),
  },
  {
    id: 3,
    img: require("../../../assets/images/CE-3.png"),
  },
  {
    id: 4,
    img: require("../../../assets/images/CE-4.png"),
  },
  {
    id: 5,
    img: require("../../../assets/images/CE-5.png"),
  },
];

export const arr9 = [
  {
    id: 1,
    img: require("../../../assets/images/HM_IV_2023_1.jpg"),
  },
  {
    id: 2,
    img: require("../../../assets/images/HM_IV_2023_2.jpg"),
  },
];

export const arr10 = [
  {
    id: 1,
    img: require("../../../assets/images/HM_IV2_2023.jpg"),
  },
];

export const arr11 = [
  {
    id: 1,
    img: require("../../../assets/images/IT_Chinpurni_IV_2024.jpg"),
  },
];

export const arr12 = [
  {
    id: 1,
    img: require("../../../assets/images/Mgmt_IV_Sonalika_2024.jpg"),
  },
];

export const arr13 = [
  {
    id: 1,
    img: require("../../../assets/images/Mgmt_IV_Vardman_2024.jpg"),
  },
];

export const arr14 = [
  {
    id: 1,
    img: require("../../../assets/images/Ghai_Hospital_IV.jpeg"),
  },
];
