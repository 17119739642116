export const ComputerScienceDepartment = [
  {
    Name: "Ruby kala(2016-20)",
    Designation: "Android Developer",
    Company: "Seasia Educational Culture",
    Mail: "rubykala753@gmail.com",
    Phone: "8968638259",
    imagePath: require("../../../assets/images/ba1.png"),
  },
  {
    Name: "Ritika Pathak(2016-20)",
    Designation: "Java developer",
    Company: "Seasia Educational Culture",
    Mail: "ritzzs395@gmail.com",
    Phone: "8427548268",
    imagePath: require("../../../assets/images/ba2.png"),
  },
  {
    Name: "Ayush Sharma(2013-17)",
    Designation: "Java developer",
    Company: "Vayam Technology Limited",
    Mail: "sharma20ayush@gmail.com",
    Phone: "7696396730",
    imagePath: require("../../../assets/images/ba3.png"),
  },
  {
    Name: "Nikita Seth(2013-17)",
    Designation: "Senior Tech Analyst",
    Company: "Oak North Global",
    Mail: "nikitasethi400@gmail.com",
    Phone: "8130972285",
    imagePath: require("../../../assets/images/ba4.png"),
  },
  {
    Name: "Kajal Satija(2015-19)",
    Designation: "Assistant System Engineer",
    Company: "TCS",
    Mail: "kajalsatija1997@gmail.com",
    Phone: "8288933130",
    imagePath: require("../../../assets/images/ba5.png"),
  },
  {
    Name: "Paras Dua(2016-20)",
    Designation: "Developer",
    Company: "TCS",
    Mail: "parasdua1997@gmail.com",
    Phone: "8568015594",
    imagePath: require("../../../assets/images/ba6.png"),
  },
  {
    Name: "Twinkle Punj(2015-19)",
    Designation: "Project developer",
    Company: "HP",
    Mail: "twinkzpunj2716@gmail.com",
    Phone: "9988313066",
    imagePath: require("../../../assets/images/ba7.png"),
  },
  {
    Name: "Ketan Sharma(2015-19)",
    Designation: "IT-operations Engineer",
    Company: "Cogniter Technologies",
    Mail: "ketan2296@gmail.com",
    Phone: "9357700096",
    imagePath: require("../../../assets/images/ba8.png"),
  },
];
export const CivilDepartment = [
  {
    Name: "Charandeep sandhi(2015-19)",
    Designation: "Senior Site Engineer",
    Company: "MAC Builders and Contractors",
    Mail: "charandeepsandhi96@gmail.com",
    Phone: "9592950983",
    imagePath: require("../../../assets/images/ba10.jpeg"),
  },
  {
    Name: "Sumita(2015-19)",
    Designation: "Engineer 1",
    Company: "Congruex Consulting ltd.",
    Mail: "jassumita@gmail.com",
    Phone: "9569876495",
    imagePath: require("../../../assets/images/ba11.jpeg"),
  },
  {
    Name: "Sharnjeet Singh Sondhi(2014-18)",
    Designation: "Highway Engineer",
    Company: "Mks Group Ltd.",
    Mail: "sharnsondhi583@gmail.com",
    Phone: "9814943320",
    imagePath: require("../../../assets/images/ba12.jpg"),
  },
  {
    Name: "Rishav Ohri(2014-18)",
    Designation: "Project Manager",
    Company: "RAP projects",
    Mail: "Rishav.ohri95@gmail.com",
    Phone: "",
    imagePath: require("../../../assets/images/ba13.jpeg"),
  },
  {
    Name: "Dinesh Sharma(2013-17)",
    Designation: "Junior Execultive Deptt.",
    Company: "Jindal reality limited",
    Mail: "Dinesh.csharma18@gmail.com",
    Phone: "9988313066",
    imagePath: require("../../../assets/images/ba14.jpeg"),
  },
  {
    Name: "Shivam Gogna(2015-19)",
    Designation: "Engineer 1",
    Company: "Congruex Consulting Asia Pacific",
    Mail: "Shivi.gogna@gmail.com",
    Phone: "7696444454",
    imagePath: require("../../../assets/images/ba15.jpg"),
  },
  {
    Name: "Nitesh Chowdhary(2013-17)",
    Designation: "Junior Engineer",
    Company: "SJ Contracts Pvt. Ltd Pune",
    Mail: "Cnitesh366@gmail.com",
    Phone: "6280174055",
    imagePath: require("../../../assets/images/ba16.jpg"),
  },
];
export const ElectronicsDepartment = [
  {
    Name: "Anu Saini(2015-19)",
    Designation: "Senior Marketing Executive",
    Company: "Zapbuild technologies",
    Mail: "sainianu906@gmail.com",
    Phone: "9417290710",
    imagePath: require("../../../assets/images/ba18.png"),
  },
  {
    Name: "Rohit Kumar(2015-19)",
    Designation: "service engineerr",
    Company: "Wonder Systems Ltd",
    Mail: "rohit.duggal.789@gmail.com",
    Phone: "8289081712",
    imagePath: require("../../../assets/images/ba19.png"),
  },
  {
    Name: "Prabhjot Singh(2015-19)",
    Designation: "enrolled in Mobile Application",
    Company: "Montreal, Quebec, Canada",
    Mail: "prabhjotflorapsf@gmail.com",
    Phone: "14383645211",
    imagePath: require("../../../assets/images/ba20.png"),
  },
  {
    Name: "Nitish(2015-19)",
    Designation: "",
    Company: "",
    Mail: "nitish.nk.2014@gmail.com",
    Phone: "9803235741",
    imagePath: require("../../../assets/images/ba21.png"),
  },
  {
    Name: "Shifali(2015-19)",
    Designation: "",
    Company: "",
    Mail: "shifalirajput97@gmail.com",
    Phone: "8283072197",
    imagePath: require("../../../assets/images/ba22.png"),
  },
  {
    Name: "Anjali(2016-20)",
    Designation: "System engineer",
    Company: "Advance Solutions",
    Mail: "anjaliangel135@gmail.com",
    Phone: "9877988230",
    imagePath: require("../../../assets/images/ba23.png"),
  },
];
export const MechanicalDepartment = [
  {
    Name: "Harmanpreet Singh(2015-19)",
    Designation: "Senior Marketing Executive",
    Company: "Jaro Top Scholar",
    Mail: "hpsoffical09@gmail.com",
    Phone: "7859020007",
    imagePath: require("../../../assets/images/ba27.png"),
  },
  {
    Name: "Jagjot Singh(2015-19)",
    Designation: "Senior Technology Analyst",
    Company: "Sukhjit Starch,phagwara",
    Mail: "jagjotnavjot@gmail.com",
    Phone: "8528427738",
    imagePath: require("../../../assets/images/ba28.png"),
  },
  {
    Name: "Jatin Duggal(2015-19)",
    Designation: "Mechanical Engineer",
    Company: "Krome Dispence",
    Mail: "jatinduggal42@gmail.com",
    Phone: "9023211992",
    imagePath: require("../../../assets/images/ba29.png"),
  },
  {
    Name: "Ranjit Singh(2014-18)",
    Designation: "GET",
    Company: "Raps itech",
    Mail: "engineeranjitsingh@gmail.com",
    Phone: "9417682948",
    imagePath: require("../../../assets/images/ba30.png"),
  },
];
export const ITDepartment = [
  {
    Name: "Mr.Madhav Bansal",
    Designation: "Web Designer & Developer",
    Company: "Director of Bansal Infosys",
    Mail: "hpsoffical09@gmail.com",
    Phone: "7859020007",
    imagePath: require("../../../assets/images/Mr.Madhav.png"),
  },
  {
    Name: "Ms. Muskaan Kashyap",
    Designation: "Salesforce Engineer",
    Company: " Grazitti Interactive",
    Mail: "jagjotnavjot@gmail.com",
    Phone: "8528427738",
    imagePath: require("../../../assets/images/muskan.png"),
  },
  {
    Name: "Ms. Sukhdeep kaur",
    Designation: "Assistant officer SWOB",
    Company: " BANK OF INDIA",
    Mail: "jatinduggal42@gmail.com",
    Phone: "9023211992",
    imagePath: require("../../../assets/images/sukhdevkaur.png"),
  },
  // {
  //   Name: "",
  //   Designation: "",
  //   Company: "",
  //   Mail: "engineeranjitsingh@gmail.com",
  //   Phone: "9417682948",
  //   // imagePath: require("../../../assets/images/"),
  // },
];

export const ManagementDepartment = [
  {
    Name: "Col. Aditya (MBA-2018 Batch)",
    Designation: "Administrative Commandant",
    Company: "Station Headquarters",
    Mail: "hpsoffical09@gmail.com",
    Phone: "7859020007",
    imagePath: require("../../../assets/images/aditya.png"),
  },
  {
    Name: "TarmanjotKaur MBA(2019-2021)",
    Designation: " Manager",
    Company: "HDFC Bank ",
    Mail: "jagjotnavjot@gmail.com",
    Phone: "8528427738",
    imagePath: require("../../../assets/images/taram.png"),
  },
  {
    Name: "Col Sanjay Shandial (MBA-2019-2021) ",
    Designation: "Commanding Officer",
    Company: "NCC Battalion",
    Mail: "jatinduggal42@gmail.com",
    Phone: "9023211992",
    imagePath: require("../../../assets/images/sanjay.png"),
  },
  {
    Name: "Col. Ajit Singh Dhillon (MBA-2018 Batch)",
    Designation: "Admin Officer",
    Company: "Indian Army",
    Mail: "engineeranjitsingh@gmail.com",
    Phone: "9417682948",
    imagePath: require("../../../assets/images/ajit.png"),
  },
];
