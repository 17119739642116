export const arr = [
    { id: 1, image: require("../../assets/images/valedictory-12.jpg"), heading:"E-Games 2021", },
    { id: 2, image: require("../../assets/images/020_1.jpg"), heading:"Khushamdeed-2021", },
    { id: 3, image: require("../../assets/images/011_3.jpg"), heading:"Major Project Exhibi", },
    { id: 4, image: require("../../assets/images/002-2.jpg"), heading:"Major Project Exhibi", },
    { id: 5, image: require("../../assets/images/Pic-3.jpg"), heading:"NSS Aug 21", },
    { id: 6, image: require("../../assets/images/004-2.jpg"), heading:"International Yoga d", },
    { id: 7, image: require("../../assets/images/Screenshot-2021-05-31-14.16.51.png"), heading:"World No Tobacco Day", },
    { id: 8, image: require("../../assets/images/006_3.jpg"), heading:"sports meet 2019", },
    { id: 9, image: require("../../assets/images/002-3.jpg"), heading:"Awareness Campaign O", },
    { id: 10, image: require("../../assets/images/DSC_0210.jpg"), heading:"Basant Panchmi 2021", },
    { id: 11, image: require("../../assets/images/016_3.jpg"), heading:"ICETEM 2021", },
    { id: 12, image: require("../../assets/images/003-1.jpg"), heading:"Swachh bharat", },
    { id: 13, image: require("../../assets/images/004-3.jpg"), heading:"Tarang", },
    { id: 14, image: require("../../assets/images/2016-09-05-PHOTO-00000949.jpg"), heading:"Teachers Day 2016", },
    { id: 15, image: require("../../assets/images/IMG-20180814-WA0024.jpg"), heading:"Workshop On 'Trave", },
    // { id: 16, image: require("../../assets/images/images/022_1.jpg"), heading:"Womens Day 2018", },
    { id: 17, image: require("../../assets/images/013_3.jpg"), heading:"Womens day 2019", },
    { id: 18, image: require("../../assets/images/001-1.jpg"), heading:"Workshop by HCL", },
    { id: 19, image: require("../../assets/images/DSC_0236.jpg"), heading:"Sayonara 2019", },
    { id: 20, image: require("../../assets/images/003-2.jpg"), heading:"Sports Meet 2015", },
    { id: 21, image: require("../../assets/images/002-4.jpg"), heading:"Sports Meet 2016", },
    { id: 22, image: require("../../assets/images/002-5.jpg"), heading:"Sports Meet 2017", },
    { id: 23, image: require("../../assets/images/024_1.jpg"), heading:"Sports Meet 2018", },
    { id: 24, image: require("../../assets/images/001-2.jpg"), heading:"Survey Camp 2017", },
    // { id: 25, image: require("../../assets/images/images/009_2.jpg"), heading:"S. Balbir Singh Scho", },
]