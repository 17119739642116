import img16 from "../../../assets/images/MGMT.jpg";
import img11 from "../../../assets/images/5-300x169.jpg";
import img15 from "../../../assets/images/5-360x270.jpg";
import img10 from "../../../assets/images/17309473_249999888795362_7248265296186591761_n.jpg";
import img4 from "../../../assets/images/17796673_257518068043544_4571591861960199897_n.jpg";
import img9 from "../../../assets/images/18157639_269324213529596_6919451569203924628_n.jpg";
import img3 from "../../../assets/images/18622639_280812329047451_4520635799064393724_n.jpg";
import img2 from "../../../assets/images/20376170_311329562662394_2983810159049860468_n.jpg";
import img1 from "../../../assets/images/21150253_323416978120319_1756975021776350864_n.jpg";
import img14 from "../../../assets/images/bp3.jpg";
import img5 from "../../../assets/images/f2-360x225.jpg";
import img6 from "../../../assets/images/i1-360x270.jpg";
import img13 from "../../../assets/images/mgmt2.jpg";
import img8 from "../../../assets/images/s7-300x225.jpg";
import img7 from "../../../assets/images/w2-360x270.jpg";
import img12 from "../../../assets/images/mgmt3.jpg";

export const Activities = [
  // { id: 1, Avatar: img1 },
  // { id: 2, Avatar: img2 },
  // { id: 3, Avatar: img3 },
  // { id: 4, Avatar: img4 },
  // { id: 5, Avatar: img5 },
  { id: 6, Avatar: img6 },
  { id: 7, Avatar: img7 },
  { id: 8, Avatar: img8 },
  // { id: 9, Avatar: img9 },
  // { id: 10, Avatar: img10 },
  // { id: 11, Avatar: img11 },
  { id: 12, Avatar: img12 },
  { id: 13, Avatar: img13 },
  { id: 14, Avatar: img14 },
  { id: 15, Avatar: img15 },
  { id: 16, Avatar: img16 },
];
