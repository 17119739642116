// import img1 from '/public/images/hotel1.jpeg';
import img1 from "../../../assets/images/hotel1.jpeg";
import img3 from "../../../assets/images/hotel2.jpeg";
import img4 from "../../../assets/images/hotel3.jpeg";
import img5 from "../../../assets/images/hotel4.jpeg";
import img2 from "../../../assets/images/hotel5.jpeg";
import img6 from "../../../assets/images/hotel6.jpeg";
import img7 from "../../../assets/images/hotel7.jpeg";
import img8 from "../../../assets/images/hotel8.jpeg";

export const Activities = [
  { id: 1, Avatar: img1 },
  // { id: 2, Avatar: img2 },
  { id: 3, Avatar: img3 },
  { id: 4, Avatar: img4 },
  { id: 5, Avatar: img5 },
  { id: 6, Avatar: img6 },
  { id: 7, Avatar: img7 },
  { id: 8, Avatar: img8 },
];
