import React from "react";

import HeaderBanner from "../../../components/HeaderBanner/index";
const EResources = () => {
  // const [tabs, SetTabs] = useState("Spardha");
  // const handelChange = (e) => {
  //   SetTabs(e);
  // };
  document.title = "E Resources";
  return (
    <div>
      <div className="">
        <HeaderBanner
          bgImage="Management"
          title="E Resources"
          currentPage=" E Resources"
        />
        <div className="bannerBotttom h-[3.5rem] w-full"></div>
      </div>
      <div className="mx-auto max-w-[1300px]">
        <div className="px-4">
          <div className=" primary-color mt-8 w-72 border-l-4 border-[#32727a] pl-3 text-2xl font-bold md:w-[28rem] md:text-[45px]">
            E-Learning at LKCTC
          </div>
          <div className="mt-6 break-all leading-7	 text-[#333333]">
            Due to prevailing conditions of COVID-19, LKCTC has introduced
            Online learning processes for students whereby teaching is being
            executed remotely and on digital platforms. As such teaching is
            learner focused; thousands of students are benefitting from
            e-learning. Teachers and students have entered a new world of
            virtual classroom learning. Keeping in mind that skill development
            and knowledge enhancement are keys to career growth in competitive
            times, various technical and co-curricular activities have been
            planned on online education platforms which have made it easy for
            students to develop new skills while continuing with their regular
            courses. Under this Initiative, comprehensive online resources have
            been generated by the Institute for effective remote learning of the
            students. As many as 32 various online webinars /corporate
            interactions (national and international) and e-events have been
            conducted for students.
          </div>
          <div className="font-semibold text-2xl">
            <div>E-Resources</div>
          </div>
          <div className="font-normal p-2 underline text-xl">
            <a
              href="https://www.lkcengg.edu.in/E_Resources.html"
              target="_blank"
              rel="noreferrer"
            >
              School of Engineering
            </a>
          </div>
          <div className="font-normal p-2 underline text-xl">
            <a
              href="https://www.kclimt.com/e-resources-2/"
              target="_blank"
              rel="noreferrer"
            >
              School of IT and Management
            </a>
          </div>
        </div>
        {/* <div className="mt-4 text-center text-lg font-bold leading-7 text-[#003857] md:mx-24 md:pb-5 md:text-[22px] md:font-bold">
          Apart from the E-learning during COVID-19, the intuition have also
          organized many online activates some of them are as below:
        </div> */}
        {/* ======================== */}
        <div className="mt-8 px-4 md:mx-[60px] lg:mx-[40px] lg:mb-10 lg:flex lg:flex-row">
          <div className="lg:mr-5 lg:w-3/5">
            {/* <div className="gap-5 md:flex md:flex-row">
              <div
                className={`${
                  tabs === "Spardha"
                    ? `primary-bg-color mx-auto w-72 px-4 py-5 text-center text-md text-white`
                    : `secondary-bg-color mx-auto w-64 px-4 py-5 text-center text-md text-white`
                } `}
                onClick={() => {
                  handelChange("Spardha");
                }}
              >
                E-Spardha-2020
              </div>
              <div
                className={`${
                  tabs === "Khushamdeed"
                    ? `primary-bg-color mx-auto w-72 px-4 py-5 text-center text-md text-white`
                    : `secondary-bg-color mx-auto w-64 px-4 py-5 text-center text-md text-white`
                } `}
                onClick={() => {
                  handelChange("Khushamdeed");
                }}
              >
                E- Khushamdeed-2020”
              </div>
              <div
                className={`${
                  tabs === "Sayonara"
                    ? `primary-bg-color mx-auto w-72 px-4 py-5 text-center text-md text-white`
                    : `secondary-bg-color mx-auto w-64 px-4 py-5 text-center text-md text-white`
                } `}
                onClick={() => {
                  handelChange("Sayonara");
                }}
              >
                E-Farewell “Sayonara-2020”
              </div>
            </div> */}
            {/* {tabs === "Spardha" && (
              <div className="mt-6 break-all leading-7	 text-[#333333] md:mb-5 md:text-lg lg:p-3">
                To channelize the energy of students amidst the lockdown due to
                COVID-19 pandemic, LKCTC, Jalandhar organized an online fest
                ‘e-SPARDHA, 2020’. A variety of creative events were conducted
                online during the e-fest. The e-fest was planned to motivate and
                encourage the students so that they can manifest their
                creativity during the lockdown. An overwhelming response was
                received as more than 700 students participated from 75
                different institutes all around the region. The major events
                planned were LAN Gaming, Indo vs. Exotic Look Challenge,
                Crossword, IQ Hunt, Circuit Mania and Quiz – Iconic Buildings in
                the World.
              </div>
            )}
            {tabs === "Khushamdeed" && (
              <div className="mt-6 break-all leading-7	 text-[#333333] md:mb-5 md:text-lg lg:p-3">
                Lyallpur Khalsa College Technical Campus, Jalandhar, School of
                Engineering, organized six day online induction program “e-
                KHUSHAMDEED-2020” for the students admitted for the session
                2020-2021. Newly admitted students were given an opportunity to
                interact with eminent personalities from academia and industry
                where they show cased their experiences and challenges of both
                the fields and narrated their perspectives on how to bridge the
                gap between the academia and industry. Students were made
                familiar with the protocols they have to abide by and the code
                of conduct during their stay in college. A session was held by
                Dr. Divya Kalra on wellness and active life style to stay
                healthy and happy. A poster making competition was organized for
                the newly enrolled students on “BEING AN ENGINEER”, in which
                students participated with full enthusiasm and passion.
              </div>
            )}
            {tabs === "Sayonara" && (
              <div className="mt-6 break-all leading-7	 text-[#333333] md:mb-5 md:text-lg lg:p-3">
                To channelize the energy of students amidst the lockdown due to
                COVID-19 pandemic, LKCTC, Jalandhar organized an online fest
                ‘e-SPARDHA, 2020’. A variety of creative events were conducted
                online during the e-fest. The e-fest was planned to motivate and
                encourage the students so that they can manifest their
                creativity during the lockdown. An overwhelming response was
                received as more than 700 students participated from 75
                different institutes all around the region. The major events
                planned were LAN Gaming, Indo vs. Exotic Look Challenge,
                Crossword, IQ Hunt, Circuit Mania and Quiz – Iconic Buildings in
                the World.
              </div>
            )} */}
          </div>
          {/* <div className="relative h-[380px] w-full pb-5 lg:flex lg:w-2/5">
            <img src={Elearningimg} alt="img" layout="fill" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EResources;
