import a1 from "../../../assets/images/001.jpg";
import a10 from "../../../assets/images/034.jpg";
import a11 from "../../../assets/images/advance solutions.png";
import a12 from "../../../assets/images/Jaro_Education_Logo.png";
import a13 from "../../../assets/images/Wipro_Primary_Logo_Color.png";
import a14 from "../../../assets/images/Toppr_logo.png";
import a15 from "../../../assets/images/logo-infosys.png";
import a16 from "../../../assets/images/netsmartz.png";
import a17 from "../../../assets/images/RADISSON.jpg";
import a18 from "../../../assets/images/sensational.jpg";
import a2 from "../../../assets/images/007_1.jpg";
import a3 from "../../../assets/images/008_1.jpg";
import a4 from "../../../assets/images/009.jpg";
import a5 from "../../../assets/images/010.jpg";
import a6 from "../../../assets/images/011.jpg";
import a7 from "../../../assets/images/031.jpg";
import a8 from "../../../assets/images/032.jpg";
import a9 from "../../../assets/images/033.jpg";
import axis from "../../../assets/images/003_1.jpg";
import b10 from "../../../assets/images/016_1.jpg";
import b11 from "../../../assets/images/pims.png";
import b12 from "../../../assets/images/KREATIVAN.png";
import b2 from "../../../assets/images/005_1.jpg";
import b3 from "../../../assets/images/004_1.jpg";
import b4 from "../../../assets/images/003.jpg";
import b5 from "../../../assets/images/002_1.jpg";
import b6 from "../../../assets/images/012.jpg";
import b7 from "../../../assets/images/013_1.jpg";
import b8 from "../../../assets/images/014.jpg";
import b9 from "../../../assets/images/015_1.jpg";
import c1 from "../../../assets/images/019_1.jpg";
import c2 from "../../../assets/images/IIT.png";
import hilton from "../../../assets/images/hilton.png";
import ibm from "../../../assets/images/ibm.png";
import jaipur from "../../../assets/images/jaipur.png";
import jw from "../../../assets/images/jw.png";
import tossGlobal from "../../../assets/images/toss-global.png";

export const arr1 = [
  {
    id: 1,
    img: a1,
  },
  {
    id: 2,
    img: a2,
  },
  {
    id: 3,
    img: a3,
  },
  {
    id: 4,
    img: a4,
  },
  {
    id: 5,
    img: a5,
  },
  {
    id: 6,
    img: a6,
  },
  {
    id: 7,
    img: a7,
  },
  {
    id: 8,
    img: a8,
  },
  {
    id: 9,
    img: a9,
  },
  {
    id: 10,
    img: axis,
  },
  {
    id: 11,
    img: jw,
  },
  {
    id: 12,
    img: a17,
  },
  {
    id: 13,
    img: a18,
  },

  // {
  //   id: 12,
  //   img: a12,
  // },
  //   {
  //     id: 13,
  //     img: a13,
  //   },
  //   {
  //     id: 14,
  //     img: a14,
  //   },
  //   {
  //     id: 15,
  //     img: a15,
  //   },
  //   {
  //     id: 16,
  //     img: a16,
  //   },
];

export const arr2 = [
  {
    id: 1,
    img: ibm,
  },
  {
    id: 2,
    img: b2,
  },
  {
    id: 3,
    img: b3,
  },
  {
    id: 4,
    img: b4,
  },
  {
    id: 5,
    img: b5,
  },
  {
    id: 6,
    img: b6,
  },
  {
    id: 7,
    img: b7,
  },
  {
    id: 8,
    img: b8,
  },
  {
    id: 9,
    img: b9,
  },
  {
    id: 10,
    img: b10,
  },
  {
    id: 11,
    img: tossGlobal,
  },
  {
    id: 12,
    img: b12,
  },
  {
    id: 13,
    img: b11,
  },
  //   {
  //     id: 12,
  //     img: b12,
  //   },
  //   {
  //     id: 13,
  //     img: b13,
  //   },
  //   {
  //     id: 14,
  //     img: b14,
  //   },
  //   {
  //     id: 15,
  //     img: b15,
  //   },
  //   {
  //     id: 16,
  //     img: b16,
  //   },
];
export const arr3 = [
  {
    id: 1,
    img: c1,
  },
  {
    id: 2,
    img: a10,
  },
  {
    id: 3,
    img: a11,
  },
  {
    id: 4,
    img: a12,
  },
  {
    id: 5,
    img: a13,
  },
  {
    id: 6,
    img: a14,
  },
  {
    id: 7,
    img: a15,
  },
  {
    id: 8,
    img: a16,
  },
  {
    id: 9,
    img: hilton,
  },
  {
    id: 10,
    img: jaipur,
  },
  {
    id: 11,
    img: c2,
  },
  //   {
  //     id: 11,
  //     img: c11,
  //   },
  //   {
  //     id: 12,
  //     img: c12,
  //   },
  //   {
  //     id: 13,
  //     img: c13,
  //   },
  //   {
  //     id: 14,
  //     img: c14,
  //   },
  //   {
  //     id: 15,
  //     img: c15,
  //   },
  //   {
  //     id: 16,
  //     img: c16,
  //   },
];
