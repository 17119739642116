import img1 from "../../../assets/images/applied1.jpg";
import img2 from "../../../assets/images/applied2.jpg";
import img3 from "../../../assets/images/applied3.jpg";
import img4 from "../../../assets/images/applied4.jpg";
import img5 from "../../../assets/images/applied5.jpg";
import img6 from "../../../assets/images/applied6.jpg";
import img7 from "../../../assets/images/applied7.jpg";
// import img8 from "../../../assets/images/c3-300x200.jpg";
// import img9 from "../../../assets/images/18157639_269324213529596_6919451569203924628_n.jpg";
// import img10 from "../../../assets/images/P_20170330_120514-1024x768.jpg";
// import img11 from "../../../assets/images/17361610_250000112128673_998382429361542235_n.jpg";
// import img12 from "../../../assets/images/s4-1-300x225.jpg";
// import img13 from "../../../assets/images/bp2-360x240.jpg";
// import img14 from "../../../assets/images/3_1.jpg";
// import img15 from "../../../assets/images/18011035_265116700617014_5287002133511512205_n-1.jpg";
// import img16 from "../../../assets/images/20257997_307635353031815_8672397722275698600_n.jpg";

export const Activities = [
  { id: 1, Avatar: img1 },
  { id: 3, Avatar: img3 },
  { id: 4, Avatar: img4 },
  { id: 5, Avatar: img5 },
  { id: 6, Avatar: img6 },
  { id: 2, Avatar: img2 },
  { id: 7, Avatar: img7 },
  // { id: 8, Avatar: img8 },
  // { id: 9, Avatar: img9 },
  // { id: 10, Avatar: img10 },
  // { id: 11, Avatar: img11 },
  // { id: 12, Avatar: img12 },
  // { id: 13, Avatar: img13 },
  // { id: 14, Avatar: img14 },
  // { id: 15, Avatar: img15 },
  // { id: 16, Avatar: img16 },
];
