import {
  BscMLS,
  OperationalTab,
  PGMedicalTechnology,
  PGMicrobiologyTab,
  PGRadiology,
  RadiologyTab,
} from "../../../components/AcademicsActivities/DepartmentOfHealthScience/ProgramsTab";
import React, { useEffect, useState } from "react";

import Courses1 from "../../../assets/images/16830962_235893863539298_4349787212567067696_n-360x240.jpg";
import Courses2 from "../../../assets/images/IMG_9482-min.JPG";
import Courses3 from "../../../assets/images/IMG_9314-min.JPG";
import Courses4 from "../../../assets/images/IMG_9296-min.JPG";
import Gallery1 from "../../../assets/images/IMG_9482-min.JPG";
import Gallery2 from "../../../assets/images/IMG_9316-min.JPG";
import Gallery3 from "../../../assets/images/IMG_9314-min.JPG";
import Gallery4 from "../../../assets/images/IMG_9296-min.JPG";
import { GalleryPopup } from "../../../components/GalleryImagePopup/GalleryPopup";
import HeaderBanner from "../../../components/HeaderBanner/index";
import { ReactComponent as Mission } from "../../../assets/images/mission.svg";
import { ReactComponent as Vision } from "../../../assets/images/vision.svg";
import guestLecture from "../../../assets/images/Guest_lecture_By_Dr.Malkit_Singh2.jpeg";
import img1 from "../../../assets/images/RIT_LAB_1.png";
import img10 from "../../../assets/images/MLS_LAB_6.png";
import img11 from "../../../assets/images/MLS_LAB_7.png";
import img2 from "../../../assets/images/RIT_LAB_2.png";
import img3 from "../../../assets/images/OT_LAB_1.png";
import img4 from "../../../assets/images/OT_LAB_2.png";
import img5 from "../../../assets/images/MLS_LAB_1.png";
import img6 from "../../../assets/images/MLS_LAB_2.png";
import img7 from "../../../assets/images/MLS_LAB_3.png";
import img8 from "../../../assets/images/MLS_LAB_4.png";
import img9 from "../../../assets/images/MLS_LAB_5.png";
import iv1 from "../../../assets/images/industrial_visit.jpeg";
import iv2 from "../../../assets/images/industrial_visit2.jpeg";
import iv3 from "../../../assets/images/Ghai_Hospital_IV.jpeg";
import lifeSupport from "../../../assets/images/Basic_Life_support.jpeg";
import medCheckup from "../../../assets/images/Medical_Checkup_Camp.jpeg";
import { motion } from "framer-motion";
import sciExb from "../../../assets/images/Science_exhibition2.jpeg";
import spellB from "../../../assets/images/Spell_B2.jpeg";
import { useLocation } from "react-router-dom";

const RITLabImages = [img1, img2]; // Array containing RIT Lab images
const OTLabImages = [img3, img4]; // Array containing OT Lab images
const MLSLabImages = [img5, img6, img7, img8, img9, img10, img11]; // Array containing MLS Lab images

// Rendering the lab images

//Merit students data

const studentData = [
  {
    sno: 1,
    rollNo: 2332721,
    name: "ARPITA PAL",
    course: "B.Sc. MLS",
    semester: "1st",
    sgpa: 8.76,
  },
  {
    sno: 2,
    rollNo: 2332741,
    name: "RAHUL KUMAR",
    course: "B.Sc. MLS",
    semester: "1st",
    sgpa: 8.72,
  },
  {
    sno: 3,
    rollNo: 2332748,
    name: "TAMANNA",
    course: "B.Sc. MLS",
    semester: "1st",
    sgpa: 8.6,
  },
  {
    sno: 4,
    rollNo: 2333086,
    name: "Komal",
    course: "M.Sc. Medical Microbiology",
    semester: "1st",
    sgpa: 9.38,
  },
  {
    sno: 5,
    rollNo: 2333094,
    name: "Rohina Parveen",
    course: "M.Sc. Medical Microbiology",
    semester: "1st",
    sgpa: 8.77,
  },
  {
    sno: 6,
    rollNo: 2233125,
    name: "SIMRANPREET KAUR",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 9,
  },
  {
    sno: 7,
    rollNo: 2233126,
    name: "SUMANPREET KAUR",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 9,
  },
  {
    sno: 8,
    rollNo: 2233120,
    name: "RUJRITA",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 8.95,
  },
  {
    sno: 9,
    rollNo: 2233127,
    name: "Taniya",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 8.67,
  },
  {
    sno: 10,
    rollNo: 2233129,
    name: "Uttam Sophia Rai",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 8.57,
  },
  {
    sno: 11,
    rollNo: 2332757,
    name: "SIMRANJIT KAUR",
    course: "B. Sc. MLS(Leet)",
    semester: "3rd Sem",
    sgpa: 8.67,
  },
  {
    sno: 12,
    rollNo: 2233113,
    name: "NAVPREET KAUR",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 8.38,
  },
  {
    sno: 13,
    rollNo: 2233124,
    name: "SIMRAN",
    course: "B. Sc. MLS",
    semester: "3rd Sem",
    sgpa: 8.24,
  },
  {
    sno: 14,
    rollNo: 2233396,
    name: "Alisha Khan",
    course: "B.Sc. Radiology and Imaging Technology",
    semester: "3rd sem",
    sgpa: 8.95,
  },
  {
    sno: 15,
    rollNo: 2233404,
    name: "Mehak",
    course: "B.Sc. Radiology and Imaging Technology",
    semester: "3rd sem",
    sgpa: 8.95,
  },
  {
    sno: 16,
    rollNo: 2233400,
    name: "Isha Thakur",
    course: "B.Sc. Radiology and Imaging Technology",
    semester: "3rd sem",
    sgpa: 8.76,
  },
  {
    sno: 17,
    rollNo: 2333017,
    name: "DIYA MATTU",
    course: "B.Sc.MTAOTT",
    semester: "1st sem",
    sgpa: 9.52,
  },
  {
    sno: 18,
    rollNo: 2333036,
    name: "sapura",
    course: "B.Sc.MTAOTT",
    semester: "1st sem",
    sgpa: 8.72,
  },
  {
    sno: 19,
    rollNo: 2333034,
    name: "RONAK RASHID",
    course: "B.Sc.MTAOTT",
    semester: "1st sem",
    sgpa: 8.52,
  },
  {
    sno: 20,
    rollNo: 2333018,
    name: "EIMAAN MAJEED",
    course: "B.Sc.MTAOTT",
    semester: "1st sem",
    sgpa: 8.52,
  },
  {
    sno: 21,
    rollNo: 2131579,
    name: "Loveleen Kaur",
    course: "B.Sc. MLS",
    semester: "5th sem",
    sgpa: 9.26,
  },
  {
    sno: 22,
    rollNo: 2132587,
    name: "Rachna",
    course: "B.Sc. MLS",
    semester: "5th sem",
    sgpa: 9.11,
  },
  {
    sno: 23,
    rollNo: 2131583,
    name: "Manisha",
    course: "B.Sc. MLS",
    semester: "5th sem",
    sgpa: 8.74,
  },
  {
    sno: 24,
    rollNo: 2131580,
    name: "Mandeep Kaur",
    course: "B.Sc. MLS",
    semester: "5th sem",
    sgpa: 8.68,
  },
  {
    sno: 25,
    rollNo: 2131590,
    name: "Shana Basri",
    course: "B.Sc. MLS",
    semester: "5th sem",
    sgpa: 8.68,
  },
  {
    sno: 26,
    rollNo: 2131589,
    name: "Rani",
    course: "B.Sc. MLS",
    semester: "5th sem",
    sgpa: 8.58,
  },
];

const sortedStudentData = [...studentData].sort((a, b) => {
  if (a.course < b.course) return -1;
  if (a.course > b.course) return 1;
  return 0;
});

// _____Vision & Courses Images______

const Activities = [
  { id: 1, title: "RIT Lab", Avatar: img1 },
  { id: 2, title: "RIT Lab", Avatar: img2 },
  { id: 3, title: "OT Lab", Avatar: img3 },
  { id: 4, title: "OT Lab", Avatar: img4 },
  { id: 5, title: "MLS Lab", Avatar: img5 },
  { id: 6, title: "MLS Lab", Avatar: img6 },
  { id: 7, title: "MLS Lab", Avatar: img7 },
  { id: 8, title: "MLS Lab", Avatar: img8 },
  { id: 9, title: "MLS Lab", Avatar: img9 },
  { id: 10, title: "MLS Lab", Avatar: img10 },
  { id: 11, title: "MLS Lab", Avatar: img11 },
];

const DepartmentActivities = [
  {
    id: 1,
    title: "Guest Lecture By Guest lecture By Dr. Malkit Singh",
    Avatar: guestLecture,
  },
  { id: 2, title: "Medical Checkup Camp", Avatar: medCheckup },
  { id: 3, title: "Spell B Event", Avatar: spellB },
  { id: 4, title: "Basic Life support", Avatar: lifeSupport },
  { id: 5, title: "Science exhibition", Avatar: sciExb },
  { id: 6, title: "Industrial Visit to PIMS", Avatar: iv1 },
  { id: 7, title: "Industrial Visit to Central Drug Lab", Avatar: iv2 },
  { id: 8, title: "Industrial Visit to Ghai Hospital", Avatar: iv3 },
];

const Activities_Lab = [
  { id: 1, title: "RIT Lab", Avatar: img1 },
  { id: 2, title: "RIT Lab", Avatar: img2 },
  { id: 3, title: "OT Lab", Avatar: img3 },
  { id: 4, title: "OT Lab", Avatar: img4 },
  { id: 5, title: "MLS Lab", Avatar: img5 },
  { id: 6, title: "MLS Lab", Avatar: img6 },
  { id: 7, title: "MLS Lab", Avatar: img7 },
  { id: 8, title: "MLS Lab", Avatar: img8 },
  { id: 9, title: "MLS Lab", Avatar: img9 },
  { id: 10, title: "MLS Lab", Avatar: img10 },
  { id: 11, title: "MLS Lab", Avatar: img11 },
];

const RIT_Labs = Activities_Lab.filter((item) => item.title === "RIT Lab");
const OT_Labs = Activities_Lab.filter((item) => item.title === "OT Lab");
const MLS_Labs = Activities_Lab.filter((item) => item.title === "MLS Lab");
const DepartmentOfHealthScience = () => {
  const location = useLocation();
  const query = Object.fromEntries(new URLSearchParams(location?.search));

  const [tabs, setTabs] = useState(query?.type || "Vision");
  const [isVisible, setIsVisible] = useState(false);
  const [subTabs, setSubTabs] = useState(query?.course || "BscMLS");

  const handelChange = (e) => {
    setTabs(e);
  };

  const tabhandel = (e) => {
    setSubTabs(e);
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isVisible ? "hidden" : "auto";
  }, [isVisible]);
  document.title = "School of Engineering";

  const visionImage = [
    {
      id: 1,
      image: Gallery1,
    },
    {
      id: 2,
      image: Gallery2,
    },
    {
      id: 3,
      image: Gallery3,
    },
    {
      id: 4,
      image: Gallery4,
    },
  ];
  const courseImage = [
    {
      id: 1,
      image: Courses1,
    },
    {
      id: 2,
      image: Courses2,
    },
    {
      id: 3,
      image: Courses3,
    },
    {
      id: 4,
      image: Courses4,
    },
  ];
  const amenitiesImage = [
    {
      id: 1,
      image: Courses1,
    },
    {
      id: 2,
      image: Courses2,
    },
    {
      id: 3,
      image: Courses3,
    },
    {
      id: 4,
      image: Courses4,
    },
  ];

  return (
    <div>
      <div className="">
        <div className="">
          <HeaderBanner
            bgImage="Management"
            title="Department of Paramedical Science"
            currentPage="Department of Paramedical Science"
          />
          <div className="bannerBotttom h-[3.5rem] w-full"></div>
        </div>
        <div className=" md:px-[4rem] lg:px-[6rem] xl:px-[10rem] 2xl:px-[18rem]">
          <div
            id="department-of-paramedical-lab-sci"
            className=" primary-color container mx-4 mt-8 w-72 border-l-4 border-[#32727a] pl-3 text-3xl font-bold xxs:w-[90%] xs:mt-20 xs:w-[95%]  xs:text-4xl sm:w-[100%] md:mx-0 lg:text-5xl  "
          >
            Department of
            <span className="secondary-color"> Paramedical Science</span>
          </div>
        </div>
      </div>
      <div className=" mb-[80px] md:w-full md:px-[2.5rem] lg:px-[2.5rem] xl:px-[10rem] 2xl:px-[18rem]">
        <div className="mx-4 mt-8 md:mx-0 lg:mt-16">
          <div className="bannerBotttom mb- rounded-t-3xl  py-10 px-8 pb-16 pt-4 text-center text-[20px] font-semibold text-white lg:mb-4 lg:flex lg:pb-8">
            <div
              className={`${
                tabs === "Vision"
                  ? ` primary-color bg-white hover:bg-white `
                  : `cursor-pointer hover:bg-[#dddddd18] `
              }mx-auto w-4/5 rounded-full py-1.5  lg:w-48 `}
              onClick={() => {
                handelChange("Vision");
              }}
            >
              Vision & Mission
            </div>
            <div
              className={`${
                tabs === "AboutUs"
                  ? ` primary-color bg-white hover:bg-white `
                  : ` cursor-pointer hover:bg-[#dddddd18] `
              } mx-auto w-4/5 rounded-full py-1.5   lg:w-48 `}
              onClick={() => {
                handelChange("AboutUs");
              }}
            >
              About Us
            </div>
            <div
              className={`${
                tabs === "Courses"
                  ? ` primary-color bg-white hover:bg-white `
                  : ` cursor-pointer hover:bg-[#dddddd18] `
              } mx-auto w-4/5 rounded-full py-1.5   lg:w-48 `}
              onClick={() => {
                handelChange("Courses");
              }}
            >
              Courses
            </div>
            <div
              className={`${
                tabs === "Amenities"
                  ? ` primary-color bg-white hover:bg-white `
                  : ` cursor-pointer  hover:bg-[#dddddd18] `
              } mx-auto w-4/5  rounded-full py-1.5  lg:w-48  `}
              onClick={() => {
                handelChange("Amenities");
              }}
            >
              Labs/Amenities
            </div>
            <div
              className={`${
                tabs === "Gallery"
                  ? ` primary-color bg-white `
                  : ` hover:bg-[#dddddd18] `
              } mx-auto w-4/5 rounded-full py-1.5 lg:w-48 `}
              onClick={() => {
                handelChange("Gallery");
              }}
            >
              Gallery/Activities
            </div>
          </div>
          <div className="-mt-9 h-10 rounded-t-3xl  bg-white"></div>
        </div>
        <div className="mx-4">
          {/* tab-1  */}
          {tabs === "Vision" && (
            <div className="">
              {/* <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4">
                {visionImage?.map((item) => (
                  <div className="m-3" key={item?.id}>
                    <div
                      className="relative w-full p-2  h-[220px] justify-center flex"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                    >
                      <img src={item?.image} alt="" className="h-[200px]  " />
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="mx-4 text-justify tracking-wider">
                Modern health care has become increasingly dependent on complex
                laboratory tests the results of which aid in the diagnosis,
                monitoring and treatment of disease. You will gain fundamental
                knowledge and skills in biological, physical and health
                sciences. Medical Laboratory Science includes laboratory
                analysis in five different disciplines: Clinical Biochemistry,
                Haematology, Histotechnology, Microbiology and Transfusion
                Science. Medical laboratory science combines the use of
                sophisticated instruments and techniques with the application of
                theoretical knowledge to perform complex procedures on tissue
                specimens, blood samples and other body fluids. The tests and
                procedures that Medical Laboratory Technologists perform provide
                critical information enabling physicians to diagnose, treat and
                monitor a patient condition.
              </div>
              <div className="">
                <div className="">
                  <div className="mt-8 rounded-2xl bg-[#081C3AD6] py-6 text-white">
                    <div className=" flex justify-center gap-2 text-3xl font-bold ">
                      <Vision className=" mt-2 h-12 w-12 rounded-full bg-white  p-2 " />
                      <h2 className="mt-2">Our </h2>
                      <h2 className="mt-2">Vision</h2>
                    </div>
                    <div className="mx-4 mt-6 text-justify text-lg tracking-wider ">
                      Paramedical program aspires to be recognized nationally
                      and internationally for quality education, excellence in
                      research and innovative practice. This program envisions
                      developing leaders in medical laboratory education whose
                      actions, discoveries and voices strengthen and transform
                      the healthcare of individuals and communities worldwide.
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="mt-8 rounded-2xl bg-[#081C3AD6] py-6 text-white">
                    <div className=" flex justify-center gap-2 text-3xl font-bold ">
                      <Mission className=" mt-2 h-12 w-12 rounded-full bg-white  p-2 " />
                      <div className="mt-2">Our </div>
                      <div className="mt-2">Mission</div>
                    </div>
                    <div className="mx-4 mt-6 text-justify text-lg tracking-wider">
                      <ul className="list-disc pl-4 text-justify text-[17px]">
                        <li>
                          Advance the profession of medical laboratory sciences/
                          Radio Imaging/ Medical Anesthesia & OTT in INDIA as
                          well as globally by graduating medical professionals
                          who will demonstrate highly competent, evidence based
                          practice and professional behavior in a dynamic health
                          care environment.
                        </li>
                        <li>
                          Prepare highly skilled and competent medical
                          professionals Scientists who demonstrate the highest
                          quality of technical, clinical and professional
                          competence that fosters respect for diversity,
                          teamwork and professional growth.
                        </li>
                        <li>
                          Educate future medical professionals / Scientists who
                          will respond ethically to the needs and use the
                          critical thinking skills in the workplace to
                          strengthen the professional environment.
                        </li>
                        <li>
                          Promote the graduating medical professionals /
                          Scientists into lifelong learning with leadership
                          qualities in the healthcare team in an ever changing
                          society.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* tab-1  */}
          {tabs === "AboutUs" && (
            <div className="">
              {/* <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4">
                {visionImage?.map((item) => (
                  <div className="m-3" key={item?.id}>
                    <div
                      className="relative w-full p-2  h-[220px] justify-center flex"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                    >
                      <img src={item?.image} alt="" className="h-[200px]  " />
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="mx-4 text-justify tracking-wider">
                Welcome to the Department of Paramedical Science, where we equip
                students with fundamental knowledge and essential skills in
                biological, physical, and health sciences. Our focus lies in
                cultivating expertise in Medical Laboratory Science, a field
                pivotal to modern healthcare, where intricate laboratory tests
                play a crucial role in diagnosis, monitoring, and treatment of
                diseases. In our department, students delve into five distinct
                disciplines of Medical Laboratory Science: Clinical
                Biochemistry, Haematology, Histotechnology, Microbiology, and
                Transfusion Science. Here, we blend theoretical knowledge with
                hands-on training, enabling our students to proficiently perform
                complex procedures on tissue specimens, blood samples, and
                various body fluids. At the core of our program is the
                integration of sophisticated instruments and cutting-edge
                techniques with theoretical understanding. Through rigorous
                training, our Medical Laboratory Technologists emerge adept at
                conducting tests and procedures that provide invaluable insights
                to physicians. These insights are critical in diagnosing
                conditions, devising effective treatment plans, and monitoring
                patient progress. One of the hallmarks of our department is our
                commitment to ensuring our students are well-prepared for the
                workforce. We boast an impressive network of partnerships with
                renowned healthcare institutions, facilitating numerous
                placement opportunities for our students. These placements not
                only provide invaluable practical experience but also serve as a
                gateway to potential career opportunities post-graduation.
                Situated in the heart of the bustling metropolis, our department
                offers an ideal location for students to immerse themselves in
                both academic and practical pursuits. With access to
                state-of-the-art facilities and resources, students are
                empowered to excel and innovate in the field of Medical
                Laboratory Science. In essence, the Department of Paramedical
                Science is dedicated to nurturing the next generation of skilled
                professionals in Medical Laboratory Science. Through
                comprehensive education, hands-on training, and extensive
                industry connections, we prepare our students to make meaningful
                contributions to the healthcare sector, ultimately improving
                patient outcomes and advancing the field of medicine.
              </div>
              <div className="">
                <div className="">
                  <div className="mt-8 rounded-2xl bg-[#081C3AD6] py-6 text-white">
                    <div className=" flex justify-center gap-2 text-3xl font-bold ">
                      <Vision className=" mt-2 h-12 w-12 rounded-full bg-white  p-2 " />
                      <h2 className="mt-2">Our </h2>
                      <h2 className="mt-2">MOUs</h2>
                    </div>
                    <div className="mx-4 mt-6 text-justify text-lg tracking-wider ">
                      <ul className="list-disc pl-4 text-justify text-[17px]">
                        <li>Krisna Laboratory</li>
                        <li>Punjab Institute of Medical Sciences Hospital</li>
                        <li>Apex Hospital</li>
                        <li>NHS Hospital</li>
                        <li>GHAI Hospital</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="mt-8 rounded-2xl bg-[#081C3AD6] py-6 text-white">
                    <div className=" flex justify-center gap-2 text-3xl font-bold ">
                      <Mission className=" mt-2 h-12 w-12 rounded-full bg-white  p-2 " />
                      <div className="mt-2">Our </div>
                      <div className="mt-2">Placements</div>
                    </div>
                    <div className="mx-4 mt-6 text-justify text-lg tracking-wider">
                      <ul className="list-disc pl-4 text-justify text-[17px]">
                        <li>
                          Vinay (Shri Guru Nanak Dev Ji Clinical Pathology
                          Laboratory)
                        </li>
                        <li>Naina (Bhai Gurdas College Sangrur)</li>
                        <li>
                          Roshan Lal (Shri Guru Nanak Dev Ji Clinical Pathology
                          Laboratory)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="mt-8 rounded-2xl bg-[#081C3AD6] py-6 text-white">
                    <div className="flex justify-center gap-2 text-3xl font-bold">
                      <Mission className="mt-2 h-12 w-12 rounded-full bg-white p-2" />
                      <div className="mt-2">Our</div>
                      <div className="mt-2">Meritorious Students</div>
                    </div>
                    <div className="mx-4 mt-6 text-justify text-lg tracking-wider">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th className="px-4 py-2">UNIVERSITY Roll No</th>
                            <th className="px-4 py-2">Student Name</th>
                            <th className="px-4 py-2">Course</th>
                            <th className="px-4 py-2">Semester</th>
                            <th className="px-4 py-2">SGPA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedStudentData?.map((student, index) => (
                            <tr key={index}>
                              <td className="px-4 py-2">{student.rollNo}</td>
                              <td className="px-4 py-2">{student.name}</td>
                              <td className="px-4 py-2">{student.course}</td>
                              <td className="px-4 py-2">{student.semester}</td>
                              <td className="px-4 py-2">{student.sgpa}</td>
                              <td className="px-4 py-2">
                                {/* Render photograph here */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* tab-2  */}
          {tabs === "Courses" && (
            <div className="">
              <div className="mx-auto  text-center font-semibold md:flex md:justify-between">
                {/* _____________1__________________ */}
                <div
                  className={`pb-5 cursor-pointer mx-auto w-[150px] z-10 ${
                    subTabs === "BscMLS" ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => tabhandel("BscMLS")}
                >
                  <div className="bg-white z-10 mx-auto w-9 rounded-full border-2 border-[#081c3a] p-3 md:relative md:top-28">
                    <div className="primary-bg-color mx-auto h-2 w-2 rounded-full "></div>
                  </div>
                  {/* _______line_______ */}
                  <motion.div
                    className={`hidden md:relative w-full top-24 h-[2px] bg-black ${
                      subTabs === "BscMLS" ? "md:block" : "md:hidden"
                    }`}
                    initial={{ x: 0 }}
                    animate={subTabs === "BscMLS" ? "open" : "closed"}
                    variants={variants}
                  ></motion.div>
                  <div className="primary-color mt-4 text-base md:text-lg tracking-[3px] leading-[26px]">
                    B.Sc. MLS
                  </div>
                </div>
                {/* ________________2_______________ */}
                <div
                  className={`pb-5 cursor-pointer mx-auto w-[250px] z-10 ${
                    subTabs === "BtechOperational"
                      ? "opacity-100"
                      : "opacity-50"
                  }`}
                  onClick={() => tabhandel("BtechOperational")}
                >
                  <div className="bg-white z-10 mx-auto w-9 rounded-full border-2 border-[#081c3a] p-3 md:relative md:top-28">
                    <div className="primary-bg-color mx-auto h-2 w-2 rounded-full "></div>
                  </div>
                  {/* _______line_______ */}
                  <motion.div
                    className={`hidden md:relative w-full top-24 h-[2px] bg-black ${
                      subTabs === "BtechOperational" ? "md:block" : "md:hidden"
                    }`}
                    initial={{ x: 0 }}
                    animate={subTabs === "BtechOperational" ? "open" : "closed"}
                    variants={variants}
                  ></motion.div>
                  <div className="primary-color mt-4 text-base md:text-lg tracking-[3px] leading-[26px]">
                    B.Sc. (MT-AOTT)
                  </div>
                </div>
                {/* ________________3_______________ */}
                <div
                  className={`pb-5 cursor-pointer mx-auto w-[250px] z-10 ${
                    subTabs === "RadiologyTab" ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => tabhandel("RadiologyTab")}
                >
                  <div className="bg-white z-10 mx-auto w-9 rounded-full border-2 border-[#081c3a] p-3 md:relative md:top-28">
                    <div className="primary-bg-color mx-auto h-2 w-2 rounded-full "></div>
                  </div>
                  {/* _______line_______ */}
                  <motion.div
                    className={`hidden md:relative w-full top-24 h-[2px] bg-black ${
                      subTabs === "RadiologyTab" ? "md:block" : "md:hidden"
                    }`}
                    initial={{ x: 0 }}
                    animate={subTabs === "RadiologyTab" ? "open" : "closed"}
                    variants={variants}
                  ></motion.div>
                  <div className="primary-color mt-4 text-base md:text-lg tracking-[3px] leading-[26px]">
                    B.Sc. (RIT)
                  </div>
                </div>

                {/* ________________4_______________ */}
                <div
                  className={`pb-5 cursor-pointer mx-auto w-[250px] z-10 ${
                    subTabs === "PGMicrobiologyTab"
                      ? "opacity-100"
                      : "opacity-50"
                  }`}
                  onClick={() => tabhandel("PGMicrobiologyTab")}
                >
                  <div className="bg-white z-10 mx-auto w-9 rounded-full border-2 border-[#081c3a] p-3 md:relative md:top-28">
                    <div className="primary-bg-color mx-auto h-2 w-2 rounded-full "></div>
                  </div>
                  {/* _______line_______ */}
                  <motion.div
                    className={`hidden md:relative w-full top-24 h-[2px] bg-black ${
                      subTabs === "PGMicrobiologyTab" ? "md:block" : "md:hidden"
                    }`}
                    initial={{ x: 0 }}
                    animate={
                      subTabs === "PGMicrobiologyTab" ? "open" : "closed"
                    }
                    variants={variants}
                  ></motion.div>
                  <div className="primary-color mt-4 text-base md:text-lg tracking-[3px] leading-[26px]">
                    M.Sc (M.Microbiology)
                  </div>
                </div>

                {/* ________________5_______________ */}
                <div
                  className={`pb-5 cursor-pointer mx-auto w-[250px] z-10 ${
                    subTabs === "PGMedicalTechnology"
                      ? "opacity-100"
                      : "opacity-50"
                  }`}
                  onClick={() => tabhandel("PGMedicalTechnology")}
                >
                  <div className="bg-white z-10 mx-auto w-9 rounded-full border-2 border-[#081c3a] p-3 md:relative md:top-28">
                    <div className="primary-bg-color mx-auto h-2 w-2 rounded-full "></div>
                  </div>
                  {/* _______line_______ */}
                  <motion.div
                    className={`hidden md:relative w-full top-24 h-[2px] bg-black ${
                      subTabs === "PGMedicalTechnology"
                        ? "md:block"
                        : "md:hidden"
                    }`}
                    initial={{ x: 0 }}
                    animate={
                      subTabs === "PGMedicalTechnology" ? "open" : "closed"
                    }
                    variants={variants}
                  ></motion.div>
                  <div className="primary-color mt-4 text-base md:text-lg tracking-[3px] leading-[26px]">
                    M.Sc (MT-AOTT)
                  </div>
                </div>

                {/* ________________6_______________ */}
                <div
                  className={`pb-5 cursor-pointer mx-auto w-[250px] z-10 ${
                    subTabs === "PGRadiology" ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => tabhandel("PGRadiology")}
                >
                  <div className="bg-white z-10 mx-auto w-9 rounded-full border-2 border-[#081c3a] p-3 md:relative md:top-28">
                    <div className="primary-bg-color mx-auto h-2 w-2 rounded-full "></div>
                  </div>
                  {/* _______line_______ */}
                  <motion.div
                    className={`hidden md:relative w-full top-24 h-[2px] bg-black ${
                      subTabs === "PGRadiology" ? "md:block" : "md:hidden"
                    }`}
                    initial={{ x: 0 }}
                    animate={subTabs === "PGRadiology" ? "open" : "closed"}
                    variants={variants}
                  ></motion.div>
                  <div className="primary-color mt-4 text-base md:text-lg tracking-[3px] leading-[26px]">
                    M.Sc (RIT)
                  </div>
                </div>
              </div>

              <div className="primary-color  mt-1">
                {subTabs === "BscMLS" && <BscMLS />}
                {subTabs === "BtechOperational" && <OperationalTab />}
                {subTabs === "RadiologyTab" && <RadiologyTab />}
                {subTabs === "PGMicrobiologyTab" && <PGMicrobiologyTab />}
                {subTabs === "PGMedicalTechnology" && <PGMedicalTechnology />}
                {subTabs === "PGRadiology" && <PGRadiology />}
              </div>
            </div>
          )}
          {/* tab-3  */}
          {tabs === "Amenities" && (
            <div className="">
              {/* <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4">
                {amenitiesImage?.map((item) => (
                  <div className="m-3" key={item?.id}>
                    <div
                      className="relative w-full p-2  h-[220px] justify-center flex"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                    >
                      <img src={item?.image} alt="" className="h-[200px]  " />
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="">
                <div className="mb-4">
                  <div className=" mt-6 text-2xl font-semibold ">
                    Laboratories
                  </div>
                </div>
                {/* <div className="">
                  <ul className="list-disc pl-4 text-justify text-[17px]">
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Lab Scientist
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Lab Supervisor
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Laboratory Manager
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Laboratory Assistant
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Laboratory Analyst
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Laboratory Officer
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Research Analyst
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Food Inspector
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Food Safety Officer
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Specialist on Subject Matter
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Quality Assurance officer
                      </div>
                    </li>
                    <li className="mb-4">
                      <div className="mb-4 text-[17px] font-bold">
                        Assistant Professor
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="">
                  <ul className="list-disc pl-4 text-justify text-[17px]">
                    <li>Radiology Lab</li>
                    <li>OT Lab</li>
                    <li>Systemic Bacteriology Lab</li>
                    <li>Clinical Microbiology Lab</li>
                    <li>Immunology and Mycology Lab</li>
                    <li>Biochemistry Lab</li>
                    <li>Human Anatomy Physiology Lab</li>
                    <li>Haematology Lab</li>
                  </ul>
                </div>

                <div>
                  <div>
                    <h2 className="text-2xl font-bold">
                      Radiology & Imaging Technology Labs
                    </h2>
                    <div className="labs-container grid grid-cols-2 gap-4">
                      {RIT_Labs.map((item) => (
                        <div key={item.id}>
                          <img
                            src={item.Avatar}
                            alt={item.title}
                            className=" w-full object-cover"
                          />
                          <p className="text-center font-semibold mt-2">
                            {item.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold">
                      Operational Theatre Labs
                    </h2>
                    <div className="labs-container grid grid-cols-2 gap-4">
                      {OT_Labs.map((item) => (
                        <div key={item.id}>
                          <img
                            src={item.Avatar}
                            alt={item.title}
                            className=" w-full object-cover"
                          />
                          <p className="text-center font-semibold mt-2">
                            {item.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold">
                      Medical Laboratory Scientist Labs
                    </h2>
                    <div className="labs-container grid grid-cols-2 gap-4">
                      {MLS_Labs.map((item) => (
                        <div key={item.id}>
                          <img
                            src={item.Avatar}
                            alt={item.title}
                            className=" w-full object-cover"
                          />
                          <p className="text-center font-semibold mt-2">
                            {item.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="grid w-full grid-cols-1 xs:grid-cols-2 lg:grid-cols-4">
                  {/* {Activities?.map((item) => (
                      <div className="m-3" key={item?.id}>
                        <div
                          onClick={() => setIsVisible(true)}
                          className="relative w-full p-2  h-[220px] justify-center flex"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          }}
                        >
                          <img
                            src={item?.Avatar}
                            alt=""
                            className="h-[200px]"
                          />
                        </div>
                      </div>
                    ))} */}

                  {/* {Activities?.map((item) => (
                    <div className="m-3" key={item?.id}>
                      <div
                        onClick={() => setIsVisible(true)}
                        className="relative w-full p-2 h-[220px] justify-center flex"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <img src={item?.Avatar} alt="" className="h-[200px]" />
                        <div className="absolute bottom-0 left-0 right-0 bg-white text-center p-2">
                          {item?.title}
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          )}
          {/* tab-3  */}
          {tabs === "Gallery" && (
            <div
              className=""
              style={{ overflow: isVisible === true ? "hidden" : "none" }}
            >
              <div className="">
                <div className="">
                  <div className="primary-color text-2xl font-semibold">
                    Departmental Activities
                  </div>
                  <div
                    style={{ display: "block", flexWrap: "wrap", gap: "20px" }}
                  >
                    {DepartmentActivities.map((activity) => (
                      <div key={activity.id} style={{ textAlign: "left" }}>
                        <h3 style={{ fontSize: "24px", marginBottom: "10px" }}>
                          {activity.title}
                        </h3>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={activity.Avatar}
                            alt={activity.title}
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />
                  <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
                    Image Gallery
                  </h1>
                  <div className="grid w-full grid-cols-1 xs:grid-cols-2 lg:grid-cols-4">
                    {Activities?.map((item) => (
                      <div className="m-3" key={item?.id}>
                        <div
                          onClick={() => setIsVisible(true)}
                          className="relative w-full p-2 h-[220px] justify-center flex"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          }}
                        >
                          <img
                            src={item?.Avatar}
                            alt=""
                            className="h-[200px]"
                          />
                          <div className="absolute bottom-0 left-0 right-0 bg-white text-center p-2">
                            {item?.title}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {isVisible && (
                <div
                  onClick={() => setIsVisible(false)}
                  className="backdrop-blur-xs fixed top-0 left-0 z-50 h-screen w-full overflow-hidden bg-black/80"
                >
                  <GalleryPopup
                    Activities={Activities}
                    setIsVisible={setIsVisible}
                    thumbsSwiper={thumbsSwiper}
                    setThumbsSwiper={setThumbsSwiper}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepartmentOfHealthScience;
