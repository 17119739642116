export const Convo2017 = [
    { id: 1, images: require('../../../assets/images/1_1.png') },
    { id: 2, images: require('../../../assets/images/1_1.png') },
    { id: 3, images: require('../../../assets/images/3.png') },
    { id: 4, images: require('../../../assets/images/3.png') },
];

export const Meet2017 = [
    { id: 1, images: require('../../../assets/images/10.png') },
    { id: 2, images: require('../../../assets/images/9.png') },
    { id: 3, images: require('../../../assets/images/8.png') },
    { id: 4, images: require('../../../assets/images/7.png') },
    { id: 4, images: require('../../../assets/images/6.png') },
    { id: 4, images: require('../../../assets/images/5.png') },
    { id: 4, images: require('../../../assets/images/4.png') },
    { id: 4, images: require('../../../assets/images/3_1.png') },
    { id: 4, images: require('../../../assets/images/2_2.png') },
];