// import img1 from '/public/images/IMG_9571-min.JPG';
import img1 from "../../../assets/images/IMG_9571-min.JPG";
import img3 from "../../../assets/images/IMG_9578-min.JPG";
import img4 from "../../../assets/images/IMG_9592-min.JPG";
import img2 from "../../../assets/images/IMG_9621-min.JPG";

export const Activities = [
  { id: 1, Avatar: img1 },
  { id: 2, Avatar: img2 },
  { id: 3, Avatar: img3 },
  { id: 4, Avatar: img4 },
];
