// import Image from 'next/image';
import Front from "../../../assets/images/DSC00216-p-1080.png";
import HeaderBanner from "../../../components/HeaderBanner";
import Home from "../../../assets/images/Home-page-slider-2-p-500.jpg";
import Mission from "../../../assets/images/mission.svg";
import React from "react";
import VSN from "../../../assets/images/vision.svg";

const PrivacyPolicy = () => {
  document.title = "Privacy Policy";
  return (
    <div>
      <div className="">
        <HeaderBanner
          bgImage="VisionBanner "
          title="Privacy Policy"
          currentPage="Privacy Policy"
        />
        <div className="bannerBotttom h-[3.5rem] w-full"></div>
      </div>
      <div className="px-[15px] max-w-[1300px] mx-auto ">
        <div className=" py-8 text-3xl font-bold md:text-5xl mb-8">
          <h1 className=" border-l-2 border-[#32727A] pl-4  text-[#081C3A]">
            Privacy
            <span className="pl-2 pb-4 text-[#32727A]"> Policy</span>
          </h1>
        </div>

        <div className=" lg:flex ">
          <div className="lg:w-1/1 lg:mx-auto">
            <p className=" px-6 text-justify text-xl leading-tight ">
              Last Updated: May 2024 <br /> Welcome to the website of LKCTC,
              Jalandhar. At LKCTC, we are committed to protecting your privacy
              and ensuring that your personal information is handled in a safe
              and responsible manner. This Privacy Policy outlines the types of
              personal information we collect, how it is used, and the choices
              you have regarding your information. By using our website, you
              consent to the collection and use of your information in
              accordance with this
            </p>
          </div>
        </div>

        <div className=" lg:flex lg:flex-row-reverse  mt-14 lg:mt-0">
          <div className="lg:w-1/1 lg:mx-auto  -mt-10 lg:mt-0 ">
            <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
              <div className="text-[#081C3A]">
                Information
                <span className="text-[#32727A]"> We Collect</span>
              </div>
            </div>
            <p className=" px-6 text-justify text-xl leading-tight ">
              When you visit our website, we may collect certain information
              about you, including: -
              <ul className="list-disc list-inside">
                <li>
                  Personal Information: We may collect personal information such
                  as your name, email address, phone number, and other contact
                  details when you voluntarily provide them to us through our
                  website's contact forms or other means.
                </li>
                <li>
                  Usage Information: We may collect information about your usage
                  of our website, such as your IP address, browser type,
                  operating system, pages visited, and referring website.
                </li>
                <li>
                  Cookies: We use cookies and similar tracking technologies to
                  enhance your experience on our website, analyze trends,
                  administer the website, and gather demographic information.
                  You can control the use of cookies through your browser
                  settings.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              How We Use
              <span className="text-[#32727A]"> Your Information</span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            When you visit our website, we may collect certain information about
            you, including: -
            <ul className="list-disc list-inside">
              <li>
                To respond to your inquiries and provide you with information
                about our programs, services, and events.
              </li>
              <li>
                To personalize your experience on our website and deliver
                content and advertisements tailored to your interests.
              </li>
              <li>
                To improve our website and analyze trends, usage, and user
                interactions.
              </li>
              <li>
                To comply with legal obligations and protect our rights and
                interests.
              </li>
            </ul>
          </p>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              Information <span className="text-[#32727A]"> Sharing</span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            We do not sell, trade, or otherwise transfer your personal
            information to third parties without your consent, except as
            described in this Privacy Policy or as required by law. We may share
            your information with trusted third-party service providers who
            assist us in operating our website, conducting our business, or
            servicing you, as long as those parties agree to keep this
            information confidential.
          </p>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              Data <span className="text-[#32727A]"> Security</span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            We are committed to ensuring the security of your personal
            information and have implemented appropriate technical and
            organizational measures to safeguard your information against
            unauthorized access, disclosure, alteration, or destruction.
          </p>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              Your <span className="text-[#32727A]">Choices</span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            You have the right to access, update, or delete your personal
            information at any time. You may also choose to opt-out of receiving
            marketing communications from us by following the unsubscribe
            instructions provided in such communications.
          </p>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              Children's <span className="text-[#32727A]">Privacy</span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            Our website is not directed at children under the age of 13, and we
            do not knowingly collect personal information from children under
            the age of 13. If you believe that we have inadvertently collected
            information from a child under 13, please contact us immediately,
            and we will take steps to delete such information.
          </p>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              Changes to This
              <span className="text-[#32727A]"> Privacy Policy </span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            We may update this Privacy Policy from time to time by posting a new
            version on our website. It is your responsibility to review this
            Privacy Policy periodically for any changes. Your continued use of
            our website after any modifications to this Privacy Policy will
            constitute your acceptance of such changes.
          </p>
        </div>

        <div className="lg:w-1/1 lg:mx-auto">
          <div className="m-4 my-5 flex justify-center gap-2 text-4xl font-bold lg:flex lg:justify-start">
            <div className="text-[#081C3A]">
              Contact
              <span className="text-[#32727A]"> Us </span>
            </div>
          </div>

          <p className=" px-6 text-justify text-xl leading-tight ">
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at [insert contact
            information]. Thank you for visiting LKCTC's website and for placing
            your trust in us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
