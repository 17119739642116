export const data = [
  {
    id: 1,
    // image: require("../../../assets/images/1.png"),
    image: require("../../assets/images/1.png"),
    name: "Hardeep Kaur",
    stream: "B.Tech. (CSE)",
    company: "GOTESO",
  },
  {
    id: 2,
    image: require("../../assets/images/6p.png"),
    name: "Pawandeep Singh",
    stream: "B.Tech. (CSE)",
    company: "NUCLEUS SOFTWARE AND ECPL",
  },
  {
    id: 3,
    image: require("../../assets/images/2.png"),
    name: "Gurpreet Singh",
    stream: "B.Tech. (ME)",
    company: "SV Footwear",
  },
  {
    id: 4,
    image: require("../../assets/images/3p.png"),
    name: "Devinderpal Singh",
    stream: "B.Tech. (ME)",
    company: "SV Footwear",
  },
  {
    id: 5,
    image: require("../../assets/images/4p.png"),
    name: "Jyoti Sharma",
    stream: "B.Tech. (CSE)",
    company: "INIZ Solutions",
  },
  {
    id: 6,
    image: require("../../assets/images/5p.png"),
    name: "Ruby Kala",
    stream: "B.Tech. (CSE)",
    company: "BEBO Technology & Seasia",
  },
  {
    id: 7,
    image: require("../../assets/images/7p.png"),
    name: "Aishlee Badhan",
    stream: "B.Tech. (CSE)",
    company: "TCS",
  },
  {
    id: 8,
    image: require("../../assets/images/8p.png"),
    name: "Ayush Yadav",
    stream: "B.Tech. (ME)",
    company: "SV Footwear",
  },
  {
    id: 9,
    image: require("../../assets/images/9p.png"),
    name: "Sweksha Shukla",
    stream: "B.Tech. (CSE)",
    company: "EDUCATION CULTURE PVT. LTD.",
  },
  {
    id: 10,
    image: require("../../assets/images/10p.png"),
    name: "Muskan Dhingra",
    stream: "B.Tech. (CSE)",
    company: "Pepcoding Education Private Limited",
  },
];
